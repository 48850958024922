import * as ActionTypes from '../../../actions';
import paginate from '../../../reducers/paginate';
import { combineReducers } from 'redux';

const visibilityFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

const invoiceTypeFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_INVOICE_TYPE_FILTER':
      return action.filter  
    default:
      return state
  }
}

const paymentTypeFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_PAYMENT_TYPE_FILTER':
      return action.filter
    default:
      return state
  }
}

const soldOnFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_SOLD_ON_FILTER':
      return action.filter;
    default:
      return state
  }
}

export default combineReducers({
  saleInvoices: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_SALE_INVOICES_REQUEST,
      ActionTypes.FETCH_SALE_INVOICES_SUCCESS,
      ActionTypes.FETCH_SALE_INVOICES_FAILURE
    ]
  }),
  saleInvoice: paginate({
    mapActionToKey: action => 'saleInvoice',
    types: [
      ActionTypes.FETCH_SALE_INVOICE_REQUEST,
      ActionTypes.FETCH_SALE_INVOICE_SUCCESS,
      ActionTypes.FETCH_SALE_INVOICE_FAILURE
    ]
  }),
  visibilityFilter,
  invoiceTypeFilter,
  paymentTypeFilter,
  soldOnFilter
})