import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  movementSourceTypes: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_MOVEMENT_SOURCE_TYPES_REQUEST,
      ActionTypes.FETCH_MOVEMENT_SOURCE_TYPES_SUCCESS,
      ActionTypes.FETCH_MOVEMENT_SOURCE_TYPES_FAILURE
    ]
  }),
  movementSourceType: paginate({
    mapActionToKey: action => 'movementSourceType',
    types: [
      ActionTypes.FETCH_MOVEMENT_SOURCE_TYPE_REQUEST,
      ActionTypes.FETCH_MOVEMENT_SOURCE_TYPE_SUCCESS,
      ActionTypes.FETCH_MOVEMENT_SOURCE_TYPE_FAILURE
    ]
  })
})