import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const dispatchOrderCategoryFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_DISPATCH_ORDER_CATEGORY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  dispatchOrders: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_DISPATCH_ORDERS_REQUEST,
      ActionTypes.FETCH_DISPATCH_ORDERS_SUCCESS,
      ActionTypes.FETCH_DISPATCH_ORDERS_FAILURE
    ]
  }),
  dispatchOrder: paginate({
    mapActionToKey: action => 'dispatchOrder',
    types: [
      ActionTypes.FETCH_DISPATCH_ORDER_REQUEST,
      ActionTypes.FETCH_DISPATCH_ORDER_SUCCESS,
      ActionTypes.FETCH_DISPATCH_ORDER_FAILURE
    ]
  }),
  dispatchOrderCategoryFilter
})