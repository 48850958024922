import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  units: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_UNITS_REQUEST,
      ActionTypes.FETCH_UNITS_SUCCESS,
      ActionTypes.FETCH_UNITS_FAILURE
    ]
  }),
  unit: paginate({
    mapActionToKey: action => 'unit',
    types: [
      ActionTypes.FETCH_UNIT_REQUEST,
      ActionTypes.FETCH_UNIT_SUCCESS,
      ActionTypes.FETCH_UNIT_FAILURE
    ]
  })
})