import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';

export class Loading extends Component {
  render() {
      return <Loader content='Cargando' active inline="centered" />
  }
}

export default class DynamicImport extends Component {
  state = {
      component: null
  }

  componentDidMount () {
    this.props.load()
    .then((component) => {
      this.setState(() => ({
        component: component.default ? component.default : component
      }))
    })
    .catch(error => console.log('Error'))
  }

  render() {
    return this.props.children(this.state.component)
  }
}