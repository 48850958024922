import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  paymentMethods: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PAYMENT_METHODS_REQUEST,
      ActionTypes.FETCH_PAYMENT_METHODS_SUCCESS,
      ActionTypes.FETCH_PAYMENT_METHODS_FAILURE
    ]
  }),
  paymentMethod: paginate({
    mapActionToKey: action => 'paymentMethod',
    types: [
      ActionTypes.FETCH_PAYMENT_METHOD_REQUEST,
      ActionTypes.FETCH_PAYMENT_METHOD_SUCCESS,
      ActionTypes.FETCH_PAYMENT_METHOD_FAILURE
    ]
  })
})