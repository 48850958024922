import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SidebarMenu } from '../../components/Sidebar';

const routeLinks = [
  {
    path: "/inventarios",
    name: "Inventario",
    icon: "block layout",
    color: "blue"
  },
  {
    path: "/inventarios/productos",
    name: "Productos",
    icon: "barcode",
    color: "blue"
  },
  {
    path: "/inventarios/ordenes_despacho",
    name: "Ordenes de Despacho",
    icon: "inbox",
    color: "blue"
  },
  {
    path: "/inventarios/conteo_inventarios",
    name: "Conteo Inventarios",
    icon: "exchange",
    color: "blue"
  },
  {
    path: "/inventarios/ajuste_inventarios",
    name: "Ajuste Inventarios",
    icon: "exchange",
    color: "blue"
  },
  {
    path: "/inventarios/traslados_bodega",
    name: "Traslados Bodega",
    icon: "exchange",
    color: "blue"
  }
]

class Inventory extends Component {
  render() {
    return (
      <Grid>
        <Grid.Column>
          <Header as="h1" color={this.props.color}>Inventarios</Header>
        </Grid.Column>
      </Grid>
    )
  }
}

const InventorySidebar = () => (
  <SidebarMenu
    items={routeLinks}
    color="black"
  />
)

export { InventorySidebar };
export default Inventory;
