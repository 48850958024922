import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  warehouses: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_WAREHOUSES_REQUEST,
      ActionTypes.FETCH_WAREHOUSES_SUCCESS,
      ActionTypes.FETCH_WAREHOUSES_FAILURE
    ]
  }),
  warehouse: paginate({
    mapActionToKey: action => 'warehouse',
    types: [
      ActionTypes.FETCH_WAREHOUSE_REQUEST,
      ActionTypes.FETCH_WAREHOUSE_SUCCESS,
      ActionTypes.FETCH_WAREHOUSE_FAILURE
    ]
  })
})