import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const visibilityFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  purchaseInvoices: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PURCHASE_INVOICES_REQUEST,
      ActionTypes.FETCH_PURCHASE_INVOICES_SUCCESS,
      ActionTypes.FETCH_PURCHASE_INVOICES_FAILURE
    ]
  }),
  purchaseInvoice: paginate({
    mapActionToKey: action => 'purchaseInvoice',
    types: [
      ActionTypes.FETCH_PURCHASE_INVOICE_REQUEST,
      ActionTypes.FETCH_PURCHASE_INVOICE_SUCCESS,
      ActionTypes.FETCH_PURCHASE_INVOICE_FAILURE
    ]
  }),
  visibilityFilter
})