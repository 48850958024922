import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import Decimal from 'decimal.js/decimal.js';
import moment from 'moment';
// let numeral = require('numeral');
// import omit from 'lodash/omit';

export const getSaleInvoices = state => {
  const {
    pagination: { saleInvoices: { saleInvoices: { list } } },
    entities: { saleInvoices }
  } = state

  const saleInvoicesList = list || { ids: [] }

  return saleInvoicesList.ids.map(id => saleInvoices[id])
}

export const getSaleInvoice = (state, _id) => {
  return { ...state.entities.saleInvoices[_id] }
}

const getVisibilityFilter = state => state.pagination.saleInvoices.visibilityFilter;
const getTypeFilter = state => state.pagination.saleInvoices.invoiceTypeFilter;
const getPaymentTypeFilter = state => state.pagination.saleInvoices.paymentTypeFilter;
const getSoldOnFilter = state => state.pagination.saleInvoices.soldOnFilter;

export const getSaleInvoicesByType = createSelector(
  getSaleInvoices,
  getTypeFilter,
  (saleInvoices, filter) => {
    switch (filter) {
      case 'SHOW_CONSIGNMENT':
        return saleInvoices.filter(t => t.is_consignment === true)
      case 'SHOW_INVOICES':
        return saleInvoices.filter(t => t.is_consignment === false)
      case 'SHOW_ALL':
      default:
        return saleInvoices
    }
  }
)

export const getSaleInvoicesByPaymentFilter = createSelector(
  getSaleInvoicesByType,
  getPaymentTypeFilter,
  (saleInvoices, filter) => {
    switch (filter) {
      case 'SHOW_ALL':
        return saleInvoices
      default:
        return saleInvoices.filter(t => t.payment_type._id === filter)
    }
  }
)

export const getSaleInvoicesBySoldOn = createSelector(
  getSaleInvoicesByPaymentFilter,
  getSoldOnFilter,
  (saleInvoices, filter) => {
    if (filter.startDate && filter.endDate) {
      return saleInvoices.filter(t => moment(t.sold_on).isBetween(filter.startDate, filter.endDate, null, '[]'))
    } else {
      return saleInvoices
    }
  }
)

export const getVisibleSaleInvoices = createSelector(
  getSaleInvoicesBySoldOn,
  getVisibilityFilter,
  (saleInvoices, filter) => {
    switch (filter) {
      case 'SHOW_BORRADOR':
        return saleInvoices.filter(t => t.status === 'BORRADOR')
      case 'SHOW_PENDIENTE':
        return saleInvoices.filter(t => t.status === 'PENDIENTE')
      case 'SHOW_PAGADA':
        return saleInvoices.filter(t => t.status === 'PAGADA')
      case 'SHOW_ANULADA':
        return saleInvoices.filter(t => t.status === 'ANULADA')
      case 'SHOW_ALL':
      default:
        return saleInvoices
    }
  }
)

export const formValues = state => getFormValues('saleInvoice')(state);

export const itemsSelector = (state) => {
  const formValues = getFormValues('saleInvoice')(state);
  return (formValues && formValues.products !== undefined) ? formValues.products : { products: [] }
};

const exemptProductsSelector = createSelector(
  itemsSelector,
  products => {
    return products.length > 0 ? products.filter(t => t.is_exempt === true) : products
  }
)

const taxableProductsSelector = createSelector(
  itemsSelector,
  products => {
    return products.length > 0 ? products.filter(t => t.is_exempt === false) : products
  }
)

const subtotalSelector = createSelector(
  itemsSelector,
  products => {
    if (products.length > 0) {
      return products.reduce((acc, product) => {
        if (product) {
          const total = new Decimal(product.total || 0.00)
          const tax = new Decimal(product.tax || 0.00)
          return new Decimal(acc).plus(total.minus(tax))
        }
        return new Decimal(acc)
      }, new Decimal(0).toDecimalPlaces(2))
    }
    return new Decimal(0.00).toDecimalPlaces(2)
  }
)
const discountSelector = createSelector(
  itemsSelector,
  products => {
    if (products.length > 0) {
      return products.reduce((acc, product) => {
        if (product) {
          const discount = new Decimal(product.discount_subtotal || 0.00)
          return new Decimal(acc).plus(discount)
        }
        return new Decimal(acc)
      }, new Decimal(0).toDecimalPlaces(2))
    }
    return new Decimal(0.00).toDecimalPlaces(2)
  }
)

const exemptSubtotalSelector = createSelector(
  exemptProductsSelector,
  products => {
    if (products.length > 0) {
      return products.reduce((acc, product) => {
        if (product) {
          const total = new Decimal(product.total || 0.00)
          // const tax = new Decimal(product.tax || 0.00)
          return total
        }
        return new Decimal(acc)
      }, new Decimal(0).toDecimalPlaces(2))
    }
    return new Decimal(0.00).toDecimalPlaces(2)
  }
)

const taxableSubtotalSelector = createSelector(
  taxableProductsSelector,
  products => {
    if (products.length > 0) {
      return products.reduce((acc, product) => {
        if (product) {
          const total = new Decimal(product.total || 0.00)
          const tax = new Decimal(product.tax || 0.00)
          return new Decimal(acc).plus(total.minus(tax))
        }
        return new Decimal(acc)
      }, new Decimal(0).toDecimalPlaces(2))
    }
    return new Decimal(0.00).toDecimalPlaces(2)
  }
)

const taxSelector = createSelector(
  taxableProductsSelector,
  products => {
    if (products.length > 0) {
      return products.reduce((acc, product) => {
        if (product) {
          const tax = product.tax || 0.00
          return new Decimal(acc).plus(tax)
        }
        return new Decimal(acc)
      }, new Decimal(0).toDecimalPlaces(2))
    }
    return new Decimal(0.00).toDecimalPlaces(2)
  }
)

export const totalSelector = createSelector(
  itemsSelector,
  discountSelector,
  subtotalSelector,
  exemptSubtotalSelector,
  taxableSubtotalSelector,
  taxSelector,
  (products, discount, subtotal, exemptSubtotal, taxableSubtotal, tax) => {
    let total = 0.00
    if (products.length > 0) {
      total = products.reduce((acc, product) => {
        if (product) {
          total = product.total || 0.00
          return new Decimal(acc).plus(total)
        }
        return new Decimal(acc)
      }, new Decimal(0).toDecimalPlaces(2))
    } else {
      total = new Decimal(0).toDecimalPlaces(2)
    }
    return  { discount: discount, subtotal: subtotal, exempt_subtotal: exemptSubtotal, taxable_subtotal: taxableSubtotal, tax: tax, total: total }
  }
)
