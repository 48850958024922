import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

class SidebarMenuItem extends Component {
  render() {
    const { item } = this.props;

    return(
      <Menu.Item as={NavLink} to={item.path} name={item.name} color={item.color} activeClassName='active'>
        <Icon name={item.icon} /> {item.name}
      </Menu.Item>
    );
  }
}

SidebarMenuItem.propTypes = {
  item: PropTypes.object.isRequired
}

export default SidebarMenuItem;
