import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import Decimal from 'decimal.js/decimal.js';
// let numeral = require('numeral');
// import omit from 'lodash/omit';

export const getTellers = state => {
  const {
    pagination: { tellers: { tellers: { list } } },
    entities: { tellers }
  } = state

  const tellersList = list || { ids: [] }

  return tellersList.ids.map(id => tellers[id])
}

export const formValues = state => getFormValues('teller')(state);

export const itemsSelector = (state) => {
  const formValues = getFormValues('teller')(state);
  return (formValues && formValues.journal !== undefined) ? formValues.journal : { journal: [] }
};

export const getOpenTeller = createSelector(
  getTellers,
  (tellers) => {
    return tellers[0]
  }
);