import * as ActionTypes from '../../../actions';
import paginate from '../../../reducers/paginate';
import { combineReducers } from 'redux';

const visibilityFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

const invoiceTypeFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_INVOICE_TYPE_FILTER':
      return action.filter  
    default:
      return state
  }
}

const paymentTypeFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_PAYMENT_TYPE_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  quotations: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_QUOTATIONS_REQUEST,
      ActionTypes.FETCH_QUOTATIONS_SUCCESS,
      ActionTypes.FETCH_QUOTATIONS_FAILURE
    ]
  }),
  quotation: paginate({
    mapActionToKey: action => 'quotation',
    types: [
      ActionTypes.FETCH_QUOTATION_REQUEST,
      ActionTypes.FETCH_QUOTATION_SUCCESS,
      ActionTypes.FETCH_QUOTATION_FAILURE
    ]
  }),
  visibilityFilter,
  invoiceTypeFilter,
  paymentTypeFilter
})