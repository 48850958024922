import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  salePoints: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_SALE_POINTS_REQUEST,
      ActionTypes.FETCH_SALE_POINTS_SUCCESS,
      ActionTypes.FETCH_SALE_POINTS_FAILURE
    ]
  }),
  salePoint: paginate({
    mapActionToKey: action => 'salePoint',
    types: [
      ActionTypes.FETCH_SALE_POINT_REQUEST,
      ActionTypes.FETCH_SALE_POINT_SUCCESS,
      ActionTypes.FETCH_SALE_POINT_FAILURE
    ]
  })
})