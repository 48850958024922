// Inventory
import React from 'react';
import DynamicImport, { Loading } from './DynamicImport';
import Inventory, { InventorySidebar } from '../scenes/Inventory';
// import Products from '../scenes/Inventory/Products';
// import Subproducts from '../scenes/Inventory/Subproducts';
// import WarehouseMovements from '../scenes/Inventory/WarehouseMovements';

const Products = (props) => (
  <DynamicImport load={() => import('../scenes/Inventory/Products')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)

const InventoryCounts = (props) => (
  <DynamicImport load={() => import('../scenes/Inventory/InventoryCounts')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)

const InventoryAdjustments = (props) => (
  <DynamicImport load={() => import('../scenes/Inventory/InventoryAdjustments')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)

const WarehouseTransfers = (props) => (
  <DynamicImport load={() => import('../scenes/Inventory/WarehouseTransfers')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)

const DispatchOrders = (props) => (
  <DynamicImport load={() => import('../scenes/Inventory/DispatchOrders')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const routes = [
  {
    path: '/inventarios',
    exact: true,
    sidebar: InventorySidebar,
    main: Inventory
  },
  {
    path: '/inventarios/productos',
    // exact: true,
    sidebar: InventorySidebar,
    main: Products
  },

  {
    path: '/inventarios/ordenes_despacho',
    // exact: true,
    sidebar: InventorySidebar,
    main: DispatchOrders
  },
  {
    path: '/inventarios/conteo_inventarios',
    // exact: true,
    sidebar: InventorySidebar,
    main: InventoryCounts
  },
  {
    path: '/inventarios/ajuste_inventarios',
    // exact: true,
    sidebar: InventorySidebar,
    main: InventoryAdjustments
  },
  {
    path: '/inventarios/traslados_bodega',
    // exact: true,
    sidebar: InventorySidebar,
    main: WarehouseTransfers
  },
]

export default routes;