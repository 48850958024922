import React from 'react';
import { compose } from 'redux';
import { Accordion, Grid, Menu, Segment, Icon } from 'semantic-ui-react';

import * as ReactabularTable from 'reactabular-table';
import * as resolve from 'table-resolver';

import { paginate } from './helpers';
import PaginationBoxView from './pagination';
import { PerPageSelector, TableWrapper } from './components';

export default class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchColumn: 'all',
      query: {},
      pagination: {
        page: 1,
        perPage: 0,
        totalRecords: 0
      },
      filterActive: false
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchColumnChange = this.onSearchColumnChange.bind(this)
    this.onSearchValueChange = this.onSearchValueChange.bind(this)
    this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pagination !== prevProps.pagination) {
      const { page, perPage, totalRecords } = this.props.pagination;
      this.setState({ pagination: {
        page,
        perPage,
        totalRecords
      }})
    }
    if (this.props.sortingColumns !== prevProps.sortingColumns) {
      const createdParams = this.createParams();

      const params = {
        ...createdParams,
        sort: this.props.sortingColumns
      }

      this.props.onChange(params)
    }
  }

  createParams() {
    const { page, perPage } = this.state.pagination;
    const query = this.state.query;
    const sortingColumns = this.props.sortingColumns;

    const params = {
      page: page,
      per_page: perPage,
      q: query,
      sort: sortingColumns
    }

    return params
  }

  onPageChange({selected}) {
    const { perPage, page, totalRecords } = this.props.pagination;
    const p = page - 1 || 0;

    const amountOfPages = totalRecords / perPage; // Math.ceil(rows.length / perPage);
    const startPage = p < amountOfPages ? p : 0;

    const pagination = {
      ...this.state.pagination,
      page: Math.min(Math.max(selected + 1, 1), amountOfPages)
      // page: selected * perPage
    }
    // console.log(paginated.rows)
    this.setState({
      pagination
    });

    const createdParams = this.createParams();

    const params = {
      ...createdParams,
      ...pagination
    }

    this.props.onChange(params)
  }

  onPerPageChange = (e, { value }) => {
    const { perPage, page, totalRecords } = this.props.pagination;
    const p = page - 1 || 0;

    const amountOfPages = totalRecords / perPage; // Math.ceil(rows.length / perPage);
    const startPage = p < amountOfPages ? p : 0;

    const pagination = {
      ...this.state.pagination,
      page: 1,
      perPage: parseInt(value, 10)
    }
    // console.log(paginated.rows)
    this.setState({
      pagination
    });

    const createdParams = this.createParams();

    const params = {
      ...createdParams,
      page: 1,
      per_page: parseInt(value, 10)
    }

    this.props.onChange(params)
  }

  onSearchColumnChange = (e, data) => {
    this.setState({
      search: {
        ...this.state.search,
        column: data.value
      }
    })
  }

  onSearchValueChange = (e, data) => {
    const pagination = {
      ...this.state.pagination,
      page: 1
    }

    this.setState({
      pagination,
      query: e
    })

    const createdParams = this.createParams();

    const params = {
      ...createdParams,
      page: 1,
      q: e
    }

    setTimeout(() => {
      this.props.onChange(params)
    }, 500)
  }

  handleFilterClick = (e, titleProps) => {
    this.setState({ filterActive: !this.state.filterActive})
  }

  render() {
    const { color, sortingColumns, columns, rows, isFetching } = this.props;
    const { pagination, searchColumn, query } = this.state;

    const resolvedColumns = resolve.columnChildren({ columns });

    const resolvedRows = compose(
      paginate(pagination),
      // sort.sorter({
      //   columns: resolvedColumns,
      //   sortingColumns,
      //   sort: orderBy,
      //   strategy: sort.strategies.byProperty
      // }),
      // search.multipleColumns({ columns, query }),
      resolve.resolve({
        columns: resolvedColumns,
        method: (extra) => compose(
          resolve.byFunction('cell.resolve')(extra),
          resolve.nested(extra)
        )
      })
    )(rows);

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {this.props.children && (
              <Accordion styled fluid>
                <Accordion.Title active={this.state.filterActive} index={0} onClick={this.handleFilterClick}>
                  <Icon name="dropdown" />
                  Filtros
                </Accordion.Title>
                <Accordion.Content active={this.state.filterActive}>
                  {this.props.children}
                </Accordion.Content>
              </Accordion>
            )}
            <ReactabularTable.Provider
              columns={resolvedColumns}
              renderers={{
                table: TableWrapper
              }}
            >
              <ReactabularTable.Header
                headerRows={resolve.headerRows({ columns })}
              />

              <ReactabularTable.Body
                rows={resolvedRows.rows}
                rowKey="_id"
              />
            </ReactabularTable.Provider>
            <Menu secondary >
              <Menu.Item>
                <div className="controls">
                  <PaginationBoxView
                    pageCount={resolvedRows.amount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    breakLabel="..."
                    breakClassName='disabled item'
                    onPageChange={this.onPageChange}
                    containerClassName='ui pagination menu'
                    pageLinkClassName='item'
                    activeClassName="active"
                    previousLinkClassName="item"
                    nextLinkClassName='item'
                    disabledClassName='disabled'
                  />
                </div>
              </Menu.Item>
              <Menu.Item position="right">
                <PerPageSelector onChange={this.onPerPageChange} />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}