import React, { Component } from 'react';
import { Card, Header, Statistic } from 'semantic-ui-react';

const StatisticCard = ({...props}) => (
  <Card className="dashboard">
    <Card.Content>
      <Card.Header>
        {props.header}
      </Card.Header>
      <Card.Meta>
        {props.value}
      </Card.Meta>
    </Card.Content>
  </Card>
)

export default StatisticCard;