// SETTINGS
import React from 'react';
import DynamicImport, { Loading } from './DynamicImport';

import Settings, { SettingsSidebar } from '../scenes/Settings';

const Parameters = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/Parameters')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Establishments = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/Establishments')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const FiscalDocuments = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/FiscalDocuments')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const PrintAuthorizations = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/PrintAuthorizations')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const SalePoints = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/SalePoints')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const PaymentTypes = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/PaymentTypes')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const PaymentMethods = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/PaymentMethods')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Units = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/Units')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const PriceTypes = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/PriceTypes')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const MovementSourceTypes = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/MovementSourceTypes')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const MovementTypes = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/MovementTypes')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const ProductCategories = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/ProductCategories')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Characteristics = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/Characteristics')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Warehouses = (props) => (
  <DynamicImport load={() => import('../scenes/Settings/Warehouses')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)

const routes = [
  {
    path: '/configuracion',
    exact: true,
    sidebar: SettingsSidebar,
    main: Settings
  },
  {
    path: '/configuracion/parametros',
    // exact: true,
    sidebar: SettingsSidebar,
    main: Parameters
  },
  {
    path: '/configuracion/establecimientos',
    // exact: true,
    sidebar: SettingsSidebar,
    main: Establishments
  },
  {
    path: '/configuracion/documentos_fiscales',
    // exact: true,
    sidebar: SettingsSidebar,
    main: FiscalDocuments
  },
  {
    path: '/configuracion/autorizaciones_impresion',
    // exact: true,
    sidebar: SettingsSidebar,
    main: PrintAuthorizations
  },
  {
    path: '/configuracion/puntos_venta',
    // exact: true,
    sidebar: SettingsSidebar,
    main: SalePoints
  },
  {
    path: '/configuracion/tipos_pagos',
    // exact: true,
    sidebar: SettingsSidebar,
    main: PaymentTypes
  },
  {
    path: '/configuracion/formas_pago',
    // exact: true,
    sidebar: SettingsSidebar,
    main: PaymentMethods
  },
  {
    path: '/configuracion/unidades',
    // exact: true,
    sidebar: SettingsSidebar,
    main: Units
  },
  {
    path: '/configuracion/caracteristicas',
    // exact: true,
    sidebar: SettingsSidebar,
    main: Characteristics
  },
  {
    path: '/configuracion/tipos_precio',
    // exact: true,
    sidebar: SettingsSidebar,
    main: PriceTypes
  },
  {
    path: '/configuracion/origenes_movimientos',
    // exact: true,
    sidebar: SettingsSidebar,
    main: MovementSourceTypes
  },
  {
    path: '/configuracion/tipos_movimientos',
    // exact: true,
    sidebar: SettingsSidebar,
    main: MovementTypes
  },
  {
    path: '/configuracion/categorias',
    // exact: true,
    sidebar: SettingsSidebar,
    main: ProductCategories,
  },
  {
    path: '/configuracion/bodegas',
    // exact: true,
    sidebar: SettingsSidebar,
    main: Warehouses
  }
]

export default routes;