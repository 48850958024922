import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  establishments: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_ESTABLISHMENTS_REQUEST,
      ActionTypes.FETCH_ESTABLISHMENTS_SUCCESS,
      ActionTypes.FETCH_ESTABLISHMENTS_FAILURE
    ]
  }),
  establishment: paginate({
    mapActionToKey: action => 'establishment',
    types: [
      ActionTypes.FETCH_ESTABLISHMENT_REQUEST,
      ActionTypes.FETCH_ESTABLISHMENT_SUCCESS,
      ActionTypes.FETCH_ESTABLISHMENT_FAILURE
    ]
  })
})