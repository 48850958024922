import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  parameters: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PARAMETERS_REQUEST,
      ActionTypes.FETCH_PARAMETERS_SUCCESS,
      ActionTypes.FETCH_PARAMETERS_FAILURE
    ]
  }),
  parameter: paginate({
    mapActionToKey: action => 'parameter',
    types: [
      ActionTypes.FETCH_PARAMETER_REQUEST,
      ActionTypes.FETCH_PARAMETER_SUCCESS,
      ActionTypes.FETCH_PARAMETER_FAILURE
    ]
  })
})