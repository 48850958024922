import React from 'react';
import { Form } from 'semantic-ui-react';

const Checkbox = ({label, input: { value, checked, onChange }, meta: { error }, ...restProps}) => (
  <Form.Checkbox
    {...restProps}
    label={label}
    value={value.toString()}
    checked={checked}
    placeholder={label}
    error={!!error}
    onClick={(e, { checked }) => onChange(checked)}
  />
)

export default Checkbox;
