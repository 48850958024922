import { schema } from 'normalizr';

// Clients Schema

export const client = new schema.Entity('clients', {}, { idAttribute: '_id' });
export const saleConsignment = new schema.Entity('saleConsignments', {}, { idAttribute: '_id'});

// Admin

export const user = new schema.Entity('users', {}, { idAttribute: '_id' });
export const currentUser = new schema.Entity('currentUser', {}, { idAttribute: '_id' });

// Inventory Schema

export const characteristic = new schema.Entity('characteristics', {}, { idAttribute: '_id' });
export const productCategory = new schema.Entity('productCategories', {}, { idAttribute: '_id' });
export const warehouse = new schema.Entity('warehouses', {}, { idAttribute: '_id' });
export const warehouseMovement = new schema.Entity('warehouseMovements', {}, { idAttribute: '_id' });
export const inventoryCount = new schema.Entity('inventoryCounts', {}, { idAttribute: '_id' });
export const inventoryAdjustment = new schema.Entity('inventoryAdjustments', {}, { idAttribute: '_id' });
export const warehouseTransfer = new schema.Entity('warehouseTransfers', {}, { idAttribute: '_id' });
export const dispatchOrder = new schema.Entity('dispatchOrders', {}, { idAttribute: '_id' });
export const product = new schema.Entity('products', {}, {idAttribute: '_id' });
export const productMovement = new schema.Entity('productMovements', {}, {idAttribute: '_id' });
export const productSerial = new schema.Entity('productSerials', {}, {idAttribute: '_id' });
export const band = new schema.Entity('bands', {}, {idAttribute: '_id' });
export const subproduct = new schema.Entity('subproducts', {}, { idAttribute: '_id' });
export const priceType = new schema.Entity('priceTypes', {}, { idAttribute: '_id'});

// People Culture Schema

export const employee = new schema.Entity('employees', {}, { idAttribute: '_id'});
export const jobTitle = new schema.Entity('jobTitles', {}, { idAttribute: '_id'});
export const department = new schema.Entity('departments', {}, { idAttribute: '_id'});

// Sales Schema

export const saleInvoice = new schema.Entity('saleInvoices', {}, { idAttribute: '_id'});
export const payment = new schema.Entity('payments', {}, { idAttribute: '_id' });
export const quotation = new schema.Entity('quotations', {}, { idAttribute: '_id' });
export const ladingBill = new schema.Entity('ladingBills', {}, { idAttribute: '_id' });
export const teller = new schema.Entity('tellers', {}, { idAttribute: '_id' });

// Production Schema

export const batch = new schema.Entity('batches', {}, { idAttribute: '_id'});
export const stage = new schema.Entity('stages', {}, { idAttribute: '_id'});
export const workOrder = new schema.Entity('workOrders', {}, { idAttribute: '_id'});

// Procurement Schema

export const supplier = new schema.Entity('suppliers', {}, { idAttribute: '_id'});
export const purchaseInvoice = new schema.Entity('purchaseInvoices', {}, { idAttribute: '_id'});
export const purchaseOrder = new schema.Entity('purchaseOrders', {}, { idAttribute: '_id'});

// Parameters Schemas

export const parameter = new schema.Entity('parameters', {}, { idAttribute: '_id'} );
export const establishment = new schema.Entity('establishments', {}, { idAttribute: '_id' });
export const fiscalDocument = new schema.Entity('fiscalDocuments', {}, { idAttribute: '_id' });
export const salePoint = new schema.Entity('salePoints', {}, { idAttribute: '_id' });
export const printAuthorization = new schema.Entity('printAuthorizations', {}, { idAttribute: '_id' });
export const paymentType = new schema.Entity('paymentTypes', {}, { idAttribute: '_id' });
export const paymentMethod = new schema.Entity('paymentMethods', {}, { idAttribute: '_id' });
export const unit = new schema.Entity('units', {}, { idAttribute: '_id' });
export const movementType = new schema.Entity('movementTypes', {}, { idAttribute: '_id' });
export const movementSourceType = new schema.Entity('movementSourceTypes', {}, { idAttribute: '_id' });
export const report = new schema.Entity('report', {}, { idAttribute: '_id'});
