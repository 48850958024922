import React from 'react';
import { Menu } from 'semantic-ui-react';

const PageView = (props) => {
  let cssClassName = props.pageClassName;
  // const linkClassName = props.pageLinkClassName;
  const onClick = props.onClick;
  const href = props.href;
  let ariaLabel = 'Page ' + props.page +
    (props.extraAriaContext ? ' ' + props.extraAriaContext : '');
  let ariaCurrent = null;

  if (props.selected) {
    ariaCurrent = 'page';
    ariaLabel = 'Page ' + props.page + ' is your current page';
    if (typeof(cssClassName) !== 'undefined') {
      cssClassName = cssClassName + ' ' + props.activeClassName;
    } else {
      cssClassName = props.activeClassName;
    }
  }

  return (
    <Menu.Item
      name={props.page.toString()}
      className={cssClassName}
      onClick={onClick}
      href={href}
      aria-label={ariaLabel}
      aria-current={ariaCurrent}
    />
  )
}

export default PageView;
