import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  movementTypes: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_MOVEMENT_TYPES_REQUEST,
      ActionTypes.FETCH_MOVEMENT_TYPES_SUCCESS,
      ActionTypes.FETCH_MOVEMENT_TYPES_FAILURE
    ]
  }),
  movementType: paginate({
    mapActionToKey: action => 'movementType',
    types: [
      ActionTypes.FETCH_MOVEMENT_TYPE_REQUEST,
      ActionTypes.FETCH_MOVEMENT_TYPE_SUCCESS,
      ActionTypes.FETCH_MOVEMENT_TYPE_FAILURE
    ]
  })
})