// Procurement
import React from 'react';
import DynamicImport, { Loading } from './DynamicImport';
import Procurement, { ProcurementSidebar } from '../scenes/Procurement';
// import Suppliers from '../scenes/Procurement/Suppliers';
// import PurchaseInvoices from '../scenes/Procurement/PurchaseInvoices';
// import PurchaseOrders from '../scenes/Procurement/PurchaseOrders';

const Suppliers = (props) => (
  <DynamicImport load={() => import('../scenes/Procurement/Suppliers')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const PurchaseInvoices = (props) => (
  <DynamicImport load={() => import('../scenes/Procurement/PurchaseInvoices')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const PurchaseOrders = (props) => (
  <DynamicImport load={() => import('../scenes/Procurement/PurchaseOrders')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const routes = [
  {
    path: '/compras',
    exact: true,
    sidebar: ProcurementSidebar,
    main: Procurement
  },
  {
    path: '/compras/proveedores',
    // exact: true,
    sidebar: ProcurementSidebar,
    main: Suppliers
  },
  {
    path: '/compras/facturas',
    // exact: true,
    sidebar: ProcurementSidebar,
    main: PurchaseInvoices
  },
  {
    path: '/compras/ordenes_compra',
    // exact: true,
    sidebar: ProcurementSidebar,
    main: PurchaseOrders
  }
]

export default routes;