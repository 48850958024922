import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  jobTitles: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_JOB_TITLES_REQUEST,
      ActionTypes.FETCH_JOB_TITLES_SUCCESS,
      ActionTypes.FETCH_JOB_TITLES_FAILURE
    ]
  }),
  jobTitle: paginate({
    mapActionToKey: action => 'jobTitle',
    types: [
      ActionTypes.FETCH_JOB_TITLE_REQUEST,
      ActionTypes.FETCH_JOB_TITLE_SUCCESS,
      ActionTypes.FETCH_JOB_TITLE_FAILURE
    ]
  })
})