import axios from 'axios'
import { normalize } from 'normalizr'
import { logoutUser } from '../scenes/Login/actions';
import omit from 'lodash/omit';

// Set default API URL
// let API_URL = "http://localhost:4000/api/"

axios.default.url = process.env.REACT_APP_API_BASE_URL;
axios.default.crossDomain = true;

const throwResponseError = (error) => {
  if (error.response) {
    // throw new Error(error.response)
    return Promise.reject(error.response.data)
  } else if (error.request) {
    // throw new Error(error.request)
    return Promise.reject(error.request)
  } else {
    // throw new Error('Error', error.statusText)
    return Promise.reject(error.statusText)
  }
}

const destroyCurrentStoredSession = (error, dispatch) => {
  dispatch(logoutUser());
  throw new Error(error);
}

const handleResponse = (error, dispatch) => {
  switch (error.response.status) {
    case 400:
      return throwResponseError(error)
    case 401:
      return destroyCurrentStoredSession(error, dispatch)
    case 403:
      return destroyCurrentStoredSession(error, dispatch)
    case 404:
    case 405:
    case 429:
      return throwResponseError(error)
    case 500:
    case 503:
      return throwResponseError(error)
    default:
      return throwResponseError(error)
  }
}

export const paginate = (params) => {
  let newParams = {
    ...params
  }

  const omitedParams = ['per_page', 'page', 'sort', 'perPage', 'totalRecords', 'q']

  if (params) {
    if (params["per_page"]) newParams.$limit = params["per_page"]
    if (params["page"]) {
      const skip = params["per_page"] * (params["page"] - 1)
      newParams.$skip = skip === 1 ? 0 : skip
    }
    if (params["sort"]) newParams.$sort = params["sort"]
    if (params["q"]) newParams = {
      ...newParams,
      ...params["q"]
    }
  }
  return {
    ...omit(newParams, omitedParams),
  }
  // return params
}

const normalizeResponse = (json, schema) => {
  if (json.data.data) {
    return normalize(json.data.data.map(obj => obj), schema)
  }
  return normalize(json.data, schema)
}

const paginateResponse = ({data}) => {
  const calculatedPage = data.skip / data.limit;
  const pagination = {
    pagination: {
      page: Math.min(Math.max(calculatedPage + 1, 1), (data.total / data.limit)),
      // page: page || 1,
      perPage: data.limit || 100,
      totalRecords: data.total || 0
    }
  }
  return pagination
}

const callApi = (endpoint, schema, authenticated, params, method, data, dispatch) => {
  const token = localStorage.getItem('accessToken') || null
  let config = {}

  if (authenticated) {
    if (token) {
      config = {
        headers: { 'Authorization': `${token}` }
      }
    }
    else {
      throw new Error("No existe token almacenado!")
    }
  }

  if (params) {
    config = {
      ...config,
      params: params
    }
  }

  if (method) {
    config = {
      ...config,
      method: method
    }
  }

  if (data) {
    config = {
      ...config,
      data: data
    }
  }

  return axios.request(endpoint, config)
    .then(json => {
      return Object.assign({},
        normalizeResponse(json, schema),
        paginateResponse(json)
      )
    })
    .catch(error => {
      return handleResponse(error, dispatch)
    })
}

export const CALL_API = 'CALL_API'

export default store => next => action => {
  const callAPI = action[CALL_API]

  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint } = callAPI
  const { authenticated, data, method, params, schema, types } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (!schema) {
    throw new Error('Specify one of the schemas.')
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [ requestType, successType, failureType ] = types
  next(actionWith({ type: requestType }))

  return callApi(endpoint, schema, authenticated, params, method, data, store.dispatch).then(
    response => next(actionWith({
      response,
      authenticated,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error._message,
      errors: error.errors
    }))
  )
}
