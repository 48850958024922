import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const perPageOptions = [
  {
    key: 10,
    text: 10,
    value: 10
  },
  {
    key: 25,
    text: 25,
    value: 25
  },
  {
    key: 50,
    text: 50,
    value: 50
  },
  {
    key: 100,
    text: 100,
    value: 100
  },
]

const PerPageSelector = ({ onChange }) => {
  return (
    <Dropdown
    name="per-page-select"
    defaultValue={perPageOptions[3].value}
    onChange={onChange}
    options={perPageOptions}
    button
  />
  )
}

export default PerPageSelector;