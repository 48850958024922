// import React, { Component } from 'react';

import concat from 'lodash/concat';
// import { Loader } from 'semantic-ui-react';

import Home from '../scenes/Home';
import clients from './clients';
import inventory from './inventory';
import peopleCulture from './people_culture';
import procurement from './procurement';
import sales from './sales';
import settings from './settings';

const home = [
  {
    path: '/',
    exact: true,
    sidebar: null,
    main: Home
  }
]

const routes = concat(
  home,
  clients,
  inventory,
  peopleCulture,
  procurement,
  sales,
  settings
)

export default routes;
