import * as ActionTypes from '../../../actions'
import { paymentMethod as paymentMethodSchema } from '../../../schema'
import { CALL_API, paginate } from '../../../middleware/api'

const fetchPaymentMethods = (params) => ({
  [CALL_API]: {
    types: [ ActionTypes.FETCH_PAYMENT_METHODS_REQUEST, ActionTypes.FETCH_PAYMENT_METHODS_SUCCESS, ActionTypes.FETCH_PAYMENT_METHODS_FAILURE ],
    endpoint: `payment_methods`,
    schema: [paymentMethodSchema],
    authenticated: true,
    params
  }
})

// export const loadPaymentMethods = (pagination = {}, sorting = {}, search = {}) => dispatch => {
//   const params = paginate(pagination, sorting, search)
//   return dispatch(fetchPaymentMethods(params))
// }

export const loadPaymentMethods = (params) => dispatch => {
  return dispatch(fetchPaymentMethods(paginate(params)))
}


const fetchPaymentMethod = id => ({
  [CALL_API]: {
    types: [ ActionTypes.FETCH_PAYMENT_METHOD_REQUEST, ActionTypes.FETCH_PAYMENT_METHOD_SUCCESS, ActionTypes.FETCH_PAYMENT_METHOD_FAILURE ],
    endpoint: `payment_methods/${id}`,
    schema: paymentMethodSchema,
    authenticated: true,
  }
})

export const loadPaymentMethod = id => dispatch => {
  return dispatch(fetchPaymentMethod(id))
}

const createPaymentMethod = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ ActionTypes.CREATE_PAYMENT_METHOD_REQUEST, ActionTypes.CREATE_PAYMENT_METHOD_SUCCESS, ActionTypes.CREATE_PAYMENT_METHOD_FAILURE ],
    endpoint: `payment_methods`,
    schema: paymentMethodSchema,
    authenticated: true,
    method: 'POST',
    data: requiredFields
  }
})

const updatePaymentMethod = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ ActionTypes.UPDATE_PAYMENT_METHOD_REQUEST, ActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS, ActionTypes.UPDATE_PAYMENT_METHOD_FAILURE ],
    endpoint: `payment_methods/${requiredFields._id}`,
    schema: paymentMethodSchema,
    authenticated: true,
    method: 'PUT',
    data: requiredFields
  }
})

export const savePaymentMethod = (requiredFields = []) => dispatch => {
  if (requiredFields._id) {
    return dispatch(updatePaymentMethod(requiredFields))
  } else {
    return dispatch(createPaymentMethod(requiredFields))
  }
}

export const resetPaymentMethod = () => {
  return {
    type: ActionTypes.RESET_PAYMENT_METHOD
  }
}
