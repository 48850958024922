import React, { Component } from 'react';
import { SidebarMenu } from '../../components/Sidebar';
import Dashboard from './Dashboard';

const routeLinks = [
  {
    path: "/ventas",
    name: "Ventas",
    icon: "block layout",
    color: "red"
  },
  {
    path: "/ventas/facturas",
    name: "Facturas",
    icon: "calculator",
    color: "red"
  },
  {
    path: "/ventas/pagos",
    name: "Pagos",
    icon: "payment",
    color: "red"
  },
  {
    path: "/ventas/remisiones",
    name: "Remisiones",
    icon: "browser",
    color: "red"
  },
  {
    path: "/ventas/cotizaciones",
    name: "Cotizaciones",
    icon: "comments",
    color: "red"
  },
  {
    path: "/ventas/cajas",
    name: "Cajas",
    icon: "archive",
    color: "red"
  },
  {
    path: "/ventas/reportes",
    name: "Reportes",
    icon: "filter",
    color: "red"
  }
]

class Sales extends Component {
  render() {
    return (
      <Dashboard />
    )
  }
}

const SalesSidebar = () => (
  <SidebarMenu
    items={routeLinks}
    color="black"
  />
)

export { SalesSidebar };
export default Sales;
