import * as ActionTypes from '../../../actions'
import { teller as tellerSchema } from '../../../schema'
import { CALL_API, paginate } from '../../../middleware/api'

const fetchTellers = (params) => ({
  [CALL_API]: {
    types: [ActionTypes.FETCH_TELLERS_REQUEST, ActionTypes.FETCH_TELLERS_SUCCESS, ActionTypes.FETCH_TELLERS_FAILURE],
    endpoint: `tellers`,
    schema: [tellerSchema],
    authenticated: true,
    params
  }
})

// export const loadTellers = (pagination = {}, sorting = {}, search = {}) => dispatch => {
//   const params = paginate(pagination, sorting, search)
//   console.log(params)
//   return dispatch(fetchTellers(params))
// }

export const loadTellers = (params) => dispatch => {
  return dispatch(fetchTellers(paginate(params)))
}

export const fetchOpenTellers = (params) => ({
  [CALL_API]: {
    types: [ActionTypes.FETCH_TELLERS_REQUEST, ActionTypes.FETCH_TELLERS_SUCCESS, ActionTypes.FETCH_TELLERS_FAILURE],
    endpoint: `tellers`,
    schema: [tellerSchema],
    authenticated: true,
    params
  }
})

const fetchTeller = id => ({
  [CALL_API]: {
    types: [ActionTypes.FETCH_TELLER_REQUEST, ActionTypes.FETCH_TELLER_SUCCESS, ActionTypes.FETCH_TELLER_FAILURE],
    endpoint: `tellers/${id}`,
    schema: tellerSchema,
    authenticated: true,
  }
})

export const loadTeller = id => dispatch => {
  return dispatch(fetchTeller(id))
}

const createTeller = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ActionTypes.CREATE_TELLER_REQUEST, ActionTypes.CREATE_TELLER_SUCCESS, ActionTypes.CREATE_TELLER_FAILURE],
    endpoint: `tellers`,
    schema: tellerSchema,
    authenticated: true,
    method: 'POST',
    data: requiredFields
  }
})

export const closeTeller = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ActionTypes.UPDATE_TELLER_REQUEST, ActionTypes.UPDATE_TELLER_SUCCESS, ActionTypes.UPDATE_TELLER_FAILURE],
    endpoint: `close_teller`,
    schema: tellerSchema,
    authenticated: true,
    method: 'POST',
    data: requiredFields
  }
})

const updateTeller = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ActionTypes.UPDATE_TELLER_REQUEST, ActionTypes.UPDATE_TELLER_SUCCESS, ActionTypes.UPDATE_TELLER_FAILURE],
    endpoint: `tellers/${requiredFields._id}`,
    schema: tellerSchema,
    authenticated: true,
    method: 'PUT',
    data: requiredFields
  }
})

export const saveTeller = (requiredFields = []) => dispatch => {
  if (requiredFields._id) {
    return dispatch(updateTeller(requiredFields))
  } else {
    return dispatch(createTeller(requiredFields))
  }
}

export const resetTeller = () => {
  return {
    type: ActionTypes.RESET_TELLER
  }
}

export const setVisibilityFilter = filter => {
  return {
    type: 'SET_VISIBILITY_FILTER',
    filter
  }
}

export const setInvoiceTypeFilter = filter => {
  return {
    type: 'SET_INVOICE_TYPE_FILTER',
    filter
  }
}

export const setPaymentTypeFilter = filter => {
  return {
    type: 'SET_PAYMENT_TYPE_FILTER',
    filter
  }
}
