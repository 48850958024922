import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const visibilityFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  payments: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PAYMENTS_REQUEST,
      ActionTypes.FETCH_PAYMENTS_SUCCESS,
      ActionTypes.FETCH_PAYMENTS_FAILURE
    ]
  }),
  payment: paginate({
    mapActionToKey: action => 'payment',
    types: [
      ActionTypes.FETCH_PAYMENT_REQUEST,
      ActionTypes.FETCH_PAYMENT_SUCCESS,
      ActionTypes.FETCH_PAYMENT_FAILURE
    ]
  }),
  visibilityFilter
})