import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SidebarMenu } from '../../components/Sidebar';

const routeLinks = [
  {
    path: "/compras",
    name: "Compras",
    icon: "block layout",
    color: "teal"
  },
  {
    path: "/compras/ordenes_compra",
    name: "Ordenes de Compra",
    icon: "shopping basket",
    color: "teal"
  },
  {
    path: "/compras/facturas",
    name: "Facturas",
    icon: "shopping bag",
    color: "teal"
  },
  {
    path: "/compras/proveedores",
    name: "Proveedores",
    icon: "globe",
    color: "teal"
  },
]

class Procurement extends Component {
  render() {
    return (
      <Grid>
        <Grid.Column>
          <Header as="h1" color="black">Compras</Header>
        </Grid.Column>
      </Grid>
    )
  }
}

const ProcurementSidebar = () => (
  <SidebarMenu
    items={routeLinks}
    color="black"
  />
)

export { ProcurementSidebar };
export default Procurement;
