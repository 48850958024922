import * as ActionTypes from '../../../actions'
import { saleInvoice as saleInvoiceSchema } from '../../../schema'
import { CALL_API, paginate } from '../../../middleware/api'

const fetchSaleInvoices = (params) => ({
  [CALL_API]: {
    types: [ActionTypes.FETCH_SALE_INVOICES_REQUEST, ActionTypes.FETCH_SALE_INVOICES_SUCCESS, ActionTypes.FETCH_SALE_INVOICES_FAILURE],
    endpoint: `sale_invoices`,
    schema: [saleInvoiceSchema],
    authenticated: true,
    params
  }
})
// reemplazar (pagination, sorting, search) por solo params
// en loadSaleInvoices y fetchSaleInvoices
// esta funcion la podes copiar asi como esta en los otros archivos de actions
export const loadSaleInvoices = (params) => dispatch => {
  return dispatch(fetchSaleInvoices(paginate(params)))
}

const fetchSaleInvoice = id => ({
  [CALL_API]: {
    types: [ActionTypes.FETCH_SALE_INVOICE_REQUEST, ActionTypes.FETCH_SALE_INVOICE_SUCCESS, ActionTypes.FETCH_SALE_INVOICE_FAILURE],
    endpoint: `sale_invoices/${id}`,
    schema: saleInvoiceSchema,
    authenticated: true,
  }
})

export const loadSaleInvoice = id => dispatch => {
  return dispatch(fetchSaleInvoice(id))
}

const createSaleInvoice = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ActionTypes.CREATE_SALE_INVOICE_REQUEST, ActionTypes.CREATE_SALE_INVOICE_SUCCESS, ActionTypes.CREATE_SALE_INVOICE_FAILURE],
    endpoint: `sale_invoices`,
    schema: saleInvoiceSchema,
    authenticated: true,
    method: 'POST',
    data: requiredFields
  }
})

const updateSaleInvoice = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ActionTypes.UPDATE_SALE_INVOICE_REQUEST, ActionTypes.UPDATE_SALE_INVOICE_SUCCESS, ActionTypes.UPDATE_SALE_INVOICE_FAILURE],
    endpoint: `sale_invoices/${requiredFields._id}`,
    schema: saleInvoiceSchema,
    authenticated: true,
    method: 'PUT',
    data: requiredFields
  }
})

export const saveSaleInvoice = (requiredFields = []) => dispatch => {
  if (requiredFields._id) {
    return dispatch(updateSaleInvoice(requiredFields))
  } else {
    return dispatch(createSaleInvoice(requiredFields))
  }
}

export const resetSaleInvoice = () => {
  return {
    type: ActionTypes.RESET_SALE_INVOICE
  }
}

export const setVisibilityFilter = filter => {
  return {
    type: 'SET_VISIBILITY_FILTER',
    filter
  }
}

export const setInvoiceTypeFilter = filter => {
  return {
    type: 'SET_INVOICE_TYPE_FILTER',
    filter
  }
}

export const setPaymentTypeFilter = filter => {
  return {
    type: 'SET_PAYMENT_TYPE_FILTER',
    filter
  }
}

export const setSoldOnFilter = filter => {
  return {
    type: 'SET_SOLD_ON_FILTER',
    filter
  }
}