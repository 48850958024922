import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  users: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_USERS_REQUEST,
      ActionTypes.FETCH_USERS_SUCCESS,
      ActionTypes.FETCH_USERS_FAILURE
    ]
  }),
  user: paginate({
    mapActionToKey: action => 'user',
    types: [
      ActionTypes.FETCH_USER_REQUEST,
      ActionTypes.FETCH_USER_SUCCESS,
      ActionTypes.FETCH_USER_FAILURE
    ]
  })
})
