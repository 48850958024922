import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  fiscalDocuments: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_FISCAL_DOCUMENTS_REQUEST,
      ActionTypes.FETCH_FISCAL_DOCUMENTS_SUCCESS,
      ActionTypes.FETCH_FISCAL_DOCUMENTS_FAILURE
    ]
  }),
  fiscalDocument: paginate({
    mapActionToKey: action => 'fiscalDocument',
    types: [
      ActionTypes.FETCH_FISCAL_DOCUMENT_REQUEST,
      ActionTypes.FETCH_FISCAL_DOCUMENT_SUCCESS,
      ActionTypes.FETCH_FISCAL_DOCUMENT_FAILURE
    ]
  })
})