import * as ActionTypes from '../../../actions'
import paginate  from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  clients: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_CLIENTS_REQUEST,
      ActionTypes.FETCH_CLIENTS_SUCCESS,
      ActionTypes.FETCH_CLIENTS_FAILURE
    ]
  }),
  client: paginate({
    mapActionToKey: action => 'client',
    types: [
      ActionTypes.FETCH_CLIENT_REQUEST,
      ActionTypes.FETCH_CLIENT_SUCCESS,
      ActionTypes.FETCH_CLIENT_FAILURE
    ]
  })
})
