import * as ActionTypes from '../../actions'
import { currentUser as currentUserSchema } from '../../schema'
import { CALL_API } from '../../middleware/api'

const fetchUser = id => ({
  [CALL_API]: {
    types: [ActionTypes.FETCH_USER_REQUEST, ActionTypes.FETCH_USER_SUCCESS, ActionTypes.FETCH_USER_FAILURE],
    endpoint: `users/${id}`,
    schema: currentUserSchema,
    authenticated: true,
  }
})

export const loadUser = id => dispatch => {
  console.log('fetch', id)
  return dispatch(fetchUser(id))
}

const updateUser = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ActionTypes.UPDATE_USER_REQUEST, ActionTypes.UPDATE_USER_SUCCESS, ActionTypes.UPDATE_USER_FAILURE],
    endpoint: `users/${requiredFields._id}`,
    schema: currentUserSchema,
    authenticated: true,
    method: 'PUT',
    data: requiredFields
  }
})

export const saveUser = (requiredFields = []) => dispatch => {
  return dispatch(updateUser(requiredFields))
}

export const resetUser = () => {
  return {
    type: ActionTypes.RESET_USER
  }
}
