import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toArray from 'lodash/toArray';
import { loginUser } from '../actions';
import LoginForm from '../components/LoginForm';

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (data) => dispatch(loginUser(data))
  };
}

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(LoginForm));
