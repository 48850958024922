import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { Input } from '../../../components/Forms'

import Logo from '../../../assets/images/logo/logo-main.png';
import { isAbsolute } from 'path';

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectToReferrer: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (data) => {
    this.props.loginUser(data)
      .then(response => this.setState({ redirectToReferrer: true }))
      .catch(response => console.log('Unable to login', response))
  }

  login = () => {
    if (this.props.isAuthenticated) this.setState({ redirectToReferrer: true })
  }

  componentWillMount() {
    document.body.classList.add('login')
    if (this.props.isAuthenticated) { this.setState({ redirectToReferrer: true })}
  }

  componentWillUnmount() {
    document.body.classList.remove('login')
  }

  render() {
    const {handleSubmit, pristine, reset, submitting} = this.props
    const { redirectToReferrer } = this.state
    const { from } = this.props.location.state || { from: { pathname: '/' } }

    if (redirectToReferrer) {
      return (
        <Redirect to={from} />
      )
    }

    return (
      <Grid
        textAlign='center'
        style={{ height: '100%' }}
      >
        <Grid.Row style={{position: 'absolute',  top: '70px'}}>
          <Grid.Column>
            <Image src={Logo} size='small' spaced/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{maxWidth: '340px'}} verticalAlign="middle">
            <Segment padded >
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign='left'>
                    <Header as="h1" size='huge'>
                      Bienvenido!
                    </Header>
                    <Header as='h1' size="huge">
                      <Header.Subheader>Inicie sesion para continuar</Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column textAlign='left'>
                    <Form size='large' onSubmit={handleSubmit(this.handleSubmit)}>
                      <Field
                          fluid
                          placeholder='Correo Electronico'
                          name="email"
                          type="email"
                          component={Input}
                        />
                        <Field
                          fluid
                          placeholder='Contrasena'
                          name='encrypted_password'
                          type='password'
                          component={Input}
                        />
                        <Button disabled={submitting} color="red">Iniciar Sesion</Button>
                        <Button type="button" disabled={pristine || submitting} onClick={reset}>
                          Cancelar
                        </Button>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

LoginForm = reduxForm({
  form: 'login'
})(LoginForm)

export default LoginForm
