import '../../config/axios'
import * as ActionTypes from '../../actions'
import axios from 'axios'
import { normalize } from 'normalizr'
import { stopSubmit } from 'redux-form'

function handleResponse(response) {
  if ((response.status >= 200) || (response.status < 300)) {
    return response
  } else {
    let error = new Error(response.errors)
    error.response = response
    throw error
  }
}

const requestLoginSuccess = response => {
  localStorage.setItem('accessToken', response.data.accessToken)
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: response.data.accessToken,
    user: response.data.user
  }
}

const requestLoginFailure = response => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    response
  }
}

const requestLogin = credentials => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    credentials
  }
}

export const loginUser = credentials => dispatch => {
  let config = {
    // url: '/session/create',
    url: '/authentication',
    method: 'POST',
    headers: { 'Content-Type':'application/json' },
    data: {...credentials, "strategy": "local"}
    // data: credentials
  }

  dispatch(requestLogin(credentials))

  return axios.request(config)
    .then(handleResponse)
    .then(json => {
      dispatch(requestLoginSuccess(json))
    })
    .catch(error => {
      dispatch(requestLoginFailure(error))
    })
}



const requestLogoutSuccess = () => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

const requestLogoutFailure = response => {
  return {
    type: ActionTypes.LOGOUT_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    response
  }
}

const requestLogout = () => {
  return {
    type: ActionTypes.LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  }
}

export const logoutUser = () => dispatch => {
  dispatch(requestLogout())
  localStorage.removeItem('accessToken')
  dispatch(requestLogoutSuccess())
}
