import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const visibilityFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  purchaseOrders: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PURCHASE_ORDERS_REQUEST,
      ActionTypes.FETCH_PURCHASE_ORDERS_SUCCESS,
      ActionTypes.FETCH_PURCHASE_ORDERS_FAILURE
    ]
  }),
  purchaseOrder: paginate({
    mapActionToKey: action => 'purchaseOrder',
    types: [
      ActionTypes.FETCH_PURCHASE_ORDER_REQUEST,
      ActionTypes.FETCH_PURCHASE_ORDER_SUCCESS,
      ActionTypes.FETCH_PURCHASE_ORDER_FAILURE
    ]
  }),
  visibilityFilter
})