import React from 'react';
import { Form } from 'semantic-ui-react';

const Input = ({label, placeholder, meta: { error }, ...restProps}) => (
  <Form.Input
    {...restProps}
    label={label}
    placeholder={!!error ? error.message : placeholder || label}
    error={!!error && !!error.message}
  />
)

export default Input;
