import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SidebarMenu } from '../../components/Sidebar';

const routeLinks = [
  {
    path: "/personal",
    name: "Recursos Humanos",
    icon: "block layout",
    color: "yellow"
  },
  {
    path: "/personal/empleados",
    name: "Empleados",
    icon: "address card",
    color: "yellow"
  },
  {
    path: "/personal/departamentos",
    name: "Departamentos",
    icon: "map signs",
    color: "yellow"
  },
  {
    path: "/personal/puestos",
    name: "Puestos",
    icon: "pin",
    color: "yellow"
  },
  {
    path: "/personal/usuarios",
    name: "Usuarios",
    icon: "address card",
    color: "yellow"
  },
]

class PeopleCulture extends Component {
  render() {
    return (
      <Grid>
        <Grid.Column>
          <Header as="h1" color="black">Recursos Humanos</Header>
        </Grid.Column>
      </Grid>
    )
  }
}

const PeopleCultureSidebar = () => (
  <SidebarMenu
    items={routeLinks}
    color="black"
  />
)

export { PeopleCultureSidebar };
export default PeopleCulture;
