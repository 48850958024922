import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  employees: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_EMPLOYEES_REQUEST,
      ActionTypes.FETCH_EMPLOYEES_SUCCESS,
      ActionTypes.FETCH_EMPLOYEES_FAILURE
    ]
  }),
  employee: paginate({
    mapActionToKey: action => 'employee',
    types: [
      ActionTypes.FETCH_EMPLOYEE_REQUEST,
      ActionTypes.FETCH_EMPLOYEE_SUCCESS,
      ActionTypes.FETCH_EMPLOYEE_FAILURE
    ]
  })
})