import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated
  }
}

class ProtectedRoute extends Component {
  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;

    return(
      <Route {...rest} render={props => (
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }} />
          )
      )} />
    )
  }
}

export default connect(
  mapStateToProps
)(ProtectedRoute)
