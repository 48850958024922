import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Menu } from 'semantic-ui-react';

import SidebarMenuItem from './SidebarMenuItem';

class SidebarMenu extends Component {
  render() {
    const { items } = this.props;

    return(
      <div>
        {items.map(item => (
          <SidebarMenuItem
            key={item.path}
            item={item}
          />
        ))}
      </div>
    );
  }
}

SidebarMenu.propTypes = {
  items: PropTypes.array.isRequired
}

export default SidebarMenu;
