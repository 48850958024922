import React from 'react';
import { Message } from 'semantic-ui-react';
import Input from './Input';
import TextArea from './TextArea';
import Select from './Select';
import DatePicker from './DatePicker';
import Checkbox from './Checkbox';
import ColorPicker from './ColorPicker';

const renderMessage = (props) => {
  if (props.errors) {
    const errorFields = Object.keys(props.errors)
    if (errorFields.length > 0) {
      const errorMessages = errorFields.map(key => props.errors[key].message)

      return (
        <Message
          negative
          header="Se encontraron errores al procesar el formulario"
          list={errorMessages}
        />
      )
    } else if (props.loading) {
      return (
        <Message
          info
          header="Cargando..."
        />
      )
    }
  }
}

export { TextArea, Input, Select, DatePicker, Checkbox, ColorPicker, renderMessage };
