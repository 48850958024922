import jwt from 'jsonwebtoken';
import * as ActionTypes from '../../actions';

const parseJwt = () => {
  const token = localStorage.getItem('accessToken');
  if (token === null) {
    return null
  }
  const decodedToken = jwt.decode(token)
  return decodedToken
}

const validateAccessToken = () => {
  const accessToken = parseJwt();

  if (!accessToken) {
    return false
  }
  return true;
}

const initialState = {
  isFetching: false,
  isAuthenticated: validateAccessToken(),
  user: parseJwt()
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        user: action.creds
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
      }
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errors: action.response.response.data.errors
      }
    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      }
    default:
      return state
  }
}

export default authentication
