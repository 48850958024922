import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import DashboardHeader from './components/DashboardHeader';
import DashboardCards from './components/DashboardCards';
import DashboardSaleInvoices from './components/DashboardSaleInvoices';

class Dashboard extends Component {
  render() {
    return(
      [
        <DashboardHeader key='header'/>,
        <DashboardCards key='cards' />,
        <DashboardSaleInvoices key='details' />
      ]
    )
  }
}

export default Dashboard;