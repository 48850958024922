import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SidebarMenu } from '../../components/Sidebar';

const routeLinks = [
  {
    path: "/clientes",
    name: "Clientes",
    icon: "block layout",
    color: "orange"
  },
  {
    path: "/clientes/clientes",
    name: "Clientes",
    icon: "address book",
    color: "orange"
  },
  {
    path: "/clientes/consignas",
    name: "Consignas",
    icon: "calculator",
    color: "orange"
  },
  {
    path: "/clientes/pagos",
    name: "Pagos",
    icon: "payment",
    color: "orange"
  }
]

class Clients extends Component {
  render() {
    return (
      <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' color="black">Clientes</Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    )
  }
}

const ClientsSidebar = () => (
  <SidebarMenu
    items={routeLinks}
    color="black"
  />
)

export { Clients, ClientsSidebar };
// export default Clients;
