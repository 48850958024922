import React, { Component } from 'react';
import { Dropdown, Menu, Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadUser, resetUser } from '../actions'
import squareImage from '../../../assets/images/avatar/small/elliot.jpg';

const mapStateToProps = (state, ownProps) => {
  const userId = state.authentication.user && state.authentication.user.userId || null
  return {
    userId,
    user: {
      ...state.entities.currentUser[userId]
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: (_id) => dispatch(loadUser(_id)),
    resetForm: () => dispatch(resetUser())
  };
}



class UserDropdown extends Component {
  componentDidMount() {
    if (this.props.userId) {
      this.props.loadUser(this.props.userId)
    }
  }

  render() {
    const { user } = this.props;

    const trigger = (
      <span>
        <Image avatar src={squareImage} /> {user && user.email}
      </span>
    )

    const options = [
      { key: 'user', text: 'Mi Perfil', icon: 'user'}
    ]

    return (
      <Dropdown as={this.props.as} trigger={trigger} options={options} pointing='top left' icon={null} />
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDropdown));