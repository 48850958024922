import React from 'react';
import DynamicImport, { Loading } from './DynamicImport';

import { Clients as ClientsDashboard, ClientsSidebar } from '../scenes/Clients';
// import Clients from '../scenes/Clients/Clients';
// import SaleConsignments from '../scenes/Clients/SaleConsignments';

const Clients = (props) => (
  <DynamicImport load={() => import('../scenes/Clients/Clients')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)

const routes = [
  {
    path: '/clientes',
    exact: true,
    sidebar: ClientsSidebar,
    main: ClientsDashboard
  },
  {
    path: '/clientes/clientes',
    // exact: true,
    sidebar: ClientsSidebar,
    main: Clients
  }
]

export default routes;