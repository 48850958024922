import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  productCategories: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PRODUCT_CATEGORIES_REQUEST,
      ActionTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS,
      ActionTypes.FETCH_PRODUCT_CATEGORIES_FAILURE
    ]
  }),
  productCategory: paginate({
    mapActionToKey: action => 'productCategory',
    types: [
      ActionTypes.FETCH_PRODUCT_CATEGORY_REQUEST,
      ActionTypes.FETCH_PRODUCT_CATEGORY_SUCCESS,
      ActionTypes.FETCH_PRODUCT_CATEGORY_FAILURE
    ]
  })
})