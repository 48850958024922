import React from 'react';
import { connect } from 'react-redux';

import { NEXT_WORK_ORDER_STAGE, ADD_WORK_ORDER_OBSERVATION } from '../../constants/modalTypes';

import PaymentFormContainer from '../../scenes/Sales/SaleInvoices/containers/PaymentFormContainer';
import AddSaleInvoiceModal from '../../scenes/Sales/LadingBills/components/AddSaleInvoiceModal';

const mapStateToProps = (state) => {
  return {
    modals: state.modals
  }
};

const MODAL_COMPONENTS = {
  PAYMENT_FORM: PaymentFormContainer,
  // ADD_WORK_ORDER: SaleInvoiceAddWorkOrderFormContainer,
  ADD_SALE_INVOICE_TO_LADING_BILL: AddSaleInvoiceModal
};

const ModalRoot = ({modals: {type, props}}) => {
  if (!type) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[type];
  return <ModalComponent {...props} />;
};

export default connect(mapStateToProps)(ModalRoot);