import React from 'react';
// import PropTypes from 'prop-types';
import { Menu, Sidebar as SemanticSidebar } from 'semantic-ui-react';

const Sidebar = ({ children, ...restProps }) => {
  return(
    <SemanticSidebar as={Menu} {...restProps} >
      {children}
    </SemanticSidebar>
  )
}

export default Sidebar;
