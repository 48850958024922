import React, { Component } from 'react';
import { Modal, Item, Button, Table, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayInsert } from 'redux-form';
import moment from 'moment';

import { loadSaleInvoices } from '../../SaleInvoices/actions';
import { getSaleInvoices } from '../../SaleInvoices/selectors';
import { showModal, hideModal } from '../../../../components/Modal/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    saleInvoices: getSaleInvoices(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSaleInvoices: (search) => dispatch(loadSaleInvoices(search)),
    showModal: (type, props) => dispatch(showModal(type, props)),
    hideModal: () => dispatch(hideModal()),
    insertProduct: (value) => dispatch(arrayInsert('ladingBill', 'products', 0, value))
  }
}

class AddSaleInvoiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saleInvoices: []
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }


  handleSearchChange = (event, {value}) => {
    const search = {
      column: 'name',
      value: value
    }
    this.props.loadSaleInvoices({ search: search })
  }

  handleSelectChange = (event, {value}) => {
    if (!value) {
      return
    }
    const product = this.props.products.find(product => { return product._id === value });

    this.setState({
      _id: value,
      name: product.name || '',
      unitValue: product.base_price || "0.00",
      isExempt: product.is_exempt || false
    })
  }

  handleSaleInvoiceSelect = (e, data, saleInvoice) => {
    let saleInvoices = this.state.saleInvoices;

    if (data.checked) {
      saleInvoices.push(saleInvoice._id)
    } else {
      const pos = saleInvoices.indexOf(saleInvoice._id);
      saleInvoices.splice(pos, 1)
    }

    this.setState({
      saleInvoices: saleInvoices
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { saleInvoices } = this.state;
    // let products = [];

    saleInvoices.forEach((saleInvoice) => {
      let saleInvoiceProducts = this.props.saleInvoices.find(t => t._id === saleInvoice)
      // console.log(saleInvoiceProducts)
      saleInvoiceProducts.products.forEach((product) => {
        // products.push({
        //   product: product.product,
        //   quantity: product.quantity,
        //   sale_invoice: saleInvoice
        // })
        this.props.insertProduct({
          product: {
            _id: product.product._id,
            name: product.product.name
          },
          quantity: product.quantity,
          sale_invoice: saleInvoice
        })
      })
    })

    this.props.hideModal()
    // const { _id, name, unitValue, isExempt } = this.state;
    // const product = {
    //   product: {
    //     _id,
    //     name,
    //     is_exempt: (this.props.isExempt === true) ? this.props.isExempt : isExempt
    //   },
    //   base_price: unitValue
    // }
    // this.props.insertProduct(product)

    // this.setState({ _id: null, name: '', unitValue: 0.00, isExempt: false })
  }

  componentDidMount() {
    const params = {
      q: {
        is_dispatched: false
      }
    }
    this.props.loadSaleInvoices(params);
  }

  render() {
    const { saleInvoices } = this.props;

    return(
      <Modal
        open={true}
        onClose={this.props.hideModal}>
        <Modal.Content>
          <Table striped selectable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Cliente</Table.HeaderCell>
                <Table.HeaderCell>Factura</Table.HeaderCell>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {saleInvoices && saleInvoices.map((saleInvoice) =>
                <Table.Row key={saleInvoice._id}>
                  <Table.Cell><Checkbox name="selected" onChange={(e, data) => this.handleSaleInvoiceSelect(e, data, saleInvoice)} /></Table.Cell>
                  <Table.Cell>{saleInvoice.client.name}</Table.Cell>
                  <Table.Cell>{saleInvoice.invoice_number}</Table.Cell>
                  <Table.Cell>{moment(saleInvoice.sold_on).format('L').toString()}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Button content="Agregar Facturas" icon="plus" onClick={this.handleSubmit} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSaleInvoiceForm));