import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  warehouseMovements: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_WAREHOUSE_MOVEMENTS_REQUEST,
      ActionTypes.FETCH_WAREHOUSE_MOVEMENTS_SUCCESS,
      ActionTypes.FETCH_WAREHOUSE_MOVEMENTS_FAILURE
    ]
  }),
  warehouseMovement: paginate({
    mapActionToKey: action => 'warehouseMovement',
    types: [
      ActionTypes.FETCH_WAREHOUSE_MOVEMENT_REQUEST,
      ActionTypes.FETCH_WAREHOUSE_MOVEMENT_SUCCESS,
      ActionTypes.FETCH_WAREHOUSE_MOVEMENT_FAILURE
    ]
  })
})