import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  suppliers: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_SUPPLIERS_REQUEST,
      ActionTypes.FETCH_SUPPLIERS_SUCCESS,
      ActionTypes.FETCH_SUPPLIERS_FAILURE
    ]
  }),
  supplier: paginate({
    mapActionToKey: action => 'supplier',
    types: [
      ActionTypes.FETCH_SUPPLIER_REQUEST,
      ActionTypes.FETCH_SUPPLIER_SUCCESS,
      ActionTypes.FETCH_SUPPLIER_FAILURE
    ]
  })
})