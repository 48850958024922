import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { savePayment, resetPayment } from '../../Payments/actions';
import { loadSaleInvoice } from '../actions';
import { loadPaymentMethods } from '../../../Settings/PaymentMethods/actions';
import { getPaymentMethods } from '../../../Settings/PaymentMethods/selectors';
import { hideModal } from '../../../../components/Modal/actions';
import PaymentForm from '../components/PaymentForm';

const mapStateToProps = (state, ownProps) => {
  return {
    saleInvoice: {
      ...state.entities.saleInvoices[ownProps._id]
    },
    paymentMethods: getPaymentMethods(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    savePayment: (data) => dispatch(savePayment(data)),
    loadSaleInvoice: (_id) => dispatch(loadSaleInvoice(_id)),
    loadPaymentMethods: () => dispatch(loadPaymentMethods()),
    hideModal: () => dispatch(hideModal()),
    resetForm: () => dispatch(resetPayment())
  };
}

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(PaymentForm));
