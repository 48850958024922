import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const inventoryAdjustmentCategoryFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_INVENTORY_ADJUSTMENT_CATEGORY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  inventoryAdjustments: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_INVENTORY_ADJUSTMENTS_REQUEST,
      ActionTypes.FETCH_INVENTORY_ADJUSTMENTS_SUCCESS,
      ActionTypes.FETCH_INVENTORY_ADJUSTMENTS_FAILURE
    ]
  }),
  inventoryAdjustment: paginate({
    mapActionToKey: action => 'inventoryAdjustment',
    types: [
      ActionTypes.FETCH_INVENTORY_ADJUSTMENT_REQUEST,
      ActionTypes.FETCH_INVENTORY_ADJUSTMENT_SUCCESS,
      ActionTypes.FETCH_INVENTORY_ADJUSTMENT_FAILURE
    ]
  }),
  inventoryAdjustmentCategoryFilter
})