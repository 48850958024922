import React from 'react';
import { Form } from 'semantic-ui-react';

const Select = ({selection, label, placeholder, options, input: { value, onChange }, meta: { error }, ...restProps}) => (
  <Form.Dropdown
    {...restProps}
    selection
    label={label}
    placeholder={label || placeholder}
    options={options}
    value={value}
    error={!!error}
    onChange={(param, data) => onChange(data.value)}
  />
)

export default Select;
