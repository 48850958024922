import React, { Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Card, Grid } from 'semantic-ui-react';
import StatisticCard from '../../../../components/StatisticCard';

import { getOpenTeller } from '../../Tellers/selectors';


const formatCurrency = (value) => {
  let nf = new Intl.NumberFormat('es-419', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return "L " + nf.format(value)
}

const mapStateToProps = (state, ownProps) => {
  const {
    pagination: { tellers: { tellers: { list } } }
  } = state

  const tellersList = list || { ids: [] }

  return {
    teller: getOpenTeller(state),
    pagination: tellersList.pagination || {},
    isFetching: tellersList.isFetching || false
  };
}

class DashboardCards extends Component {
  render() {
    const { teller } = this.props;

    return (
      <Grid>
         <Grid.Row>
          <Grid.Column width={16}>
            <Card.Group itemsPerRow={2}>
              <StatisticCard header="Total Recibido" value={formatCurrency(teller && teller.total_payments || 0.00)} />
              <StatisticCard header="Total Ventas" value={formatCurrency(teller && teller.total_sale_invoices || 0.00)} />
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(connect(mapStateToProps, null)(DashboardCards));
