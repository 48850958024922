import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const inventoryCountCategoryFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_INVENTORY_COUNT_CATEGORY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  inventoryCounts: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_INVENTORY_COUNTS_REQUEST,
      ActionTypes.FETCH_INVENTORY_COUNTS_SUCCESS,
      ActionTypes.FETCH_INVENTORY_COUNTS_FAILURE
    ]
  }),
  inventoryCount: paginate({
    mapActionToKey: action => 'inventoryCount',
    types: [
      ActionTypes.FETCH_INVENTORY_COUNT_REQUEST,
      ActionTypes.FETCH_INVENTORY_COUNT_SUCCESS,
      ActionTypes.FETCH_INVENTORY_COUNT_FAILURE
    ]
  }),
  inventoryCountCategoryFilter
})