import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import { Button, Card, Divider, Form, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { Input, DatePicker, Select, renderMessage } from '../../../../components/Forms';
import moment from 'moment';

const formatCurrency = (value) => {
  let nf = new Intl.NumberFormat('es-419', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return "L " + nf.format(value)
}

const PaymentMethodSelector = ({currentPaymentMethod, paymentMethods, onClick}) => (
  <Segment basic>
    <Header content="Metodo de Pago" dividing/>
    <Card.Group itemsPerRow='4'>
      {paymentMethods.map(({value, text, icon}) => (
        <Card key={value} centered onClick={() => onClick(value)} raised={currentPaymentMethod === value ? true : false} >
          <Card.Content textAlign='center'>
            <Card.Header textAlign='center'>
              <Icon name={icon} size='large' color={currentPaymentMethod === value ? "green" : "grey"} />
            </Card.Header>
            <Card.Content textAlign='center'>
              {text}
          </Card.Content>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </Segment>
)

const PaymentFields = () => (
  <Segment basic>
    <Field
      name="concept"
      label="Concepto"
      type="text"
      component={Input}
      required
    />
    <Field
      name="payment_date"
      label="Fecha"
      type="date"
      component={Input}
      format={(value, name) => moment(value).format(moment.HTML5_FMT.DATE)}
      parse={(value) => moment(value)}
      required
    />
    <Field
      name="sale_invoices.[0].payment_amount"
      label="Monto a Pagar"
      type="text"
      required
      component={Input}
    />
  </Segment>
)

const CreditCardFields = () => (
  <Segment basic>
    <Field
      name="details.card_holder"
      label="Nombre en Tarjeta"
      type="text"
      component={Input}
    />
    <Field
      name="details.card_number"
      label="Numero de Tarjeta"
      type="text"
      component={Input}
    />
    <Field
      name="details.card_authorization_number"
      label="Autorizacion"
      type="text"
      required
      component={Input}
    />
  </Segment>
)

const DepositFields = () => (
  <Segment basic>
    <Field
      name="details.bank_name"
      label="Banco"
      type="text"
      component={Input}
    />
    <Field
      name="details.reference_number"
      label="Numero de Referencia"
      type="text"
      component={Input}
    />
  </Segment>
)

const CheckFields = () => (
  <Segment basic>
    <Field
      name="details.bank_name"
      label="Banco"
      type="text"
      component={Input}
    />
    <Field
      name="details.check_number"
      label="Numero de Cheque"
      type="text"
      component={Input}
    />
  </Segment>
)

const PaymentMethodFields = ({paymentMethod}) => {
  switch (paymentMethod) {
    case 'card':
      return <CreditCardFields />
    case 'deposit':
      return <DepositFields />
    case 'check':
      return <CheckFields />
    default: return null
  }
}

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: [
        { value: 'cash', text: 'Efectivo', icon: 'money bill alternate' },
        { value: 'card', text: 'Tarjeta Credito/Debido', icon: 'credit card' },
        { value: 'deposit', text: 'Deposito', icon: 'exchange' },
        { value: 'check', text: 'Cheque', icon: 'write' },
      ],
      paymentMethod: 'cash',

    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPaymentMethodClick = this.onPaymentMethodClick.bind(this);
  }

  handleSubmit = (data) => {
    const { history, saleInvoice } = this.props;

    return this.props.savePayment({...data, total: saleInvoice.total})
      .then(response => {
        console.log(response)
        if (response.errors) {
          throw new SubmissionError(response.errors)
        }

        if (data._id) {
          this.props.hideModal()
          history.push(`/ventas/pagos/${data._id}`)
        } else if (response.response.result) {
          this.props.hideModal()
          history.push(`/ventas/pagos/${response.response.result}`)
        }
      })
  }

  onPaymentMethodClick = (value) => {
    this.props.change('payment_method', value)
    this.setState({ paymentMethod: value })
  }

  componentDidMount() {
    const { _id } = this.props;
    // this.props.loadPaymentMethods();
    this.props.loadSaleInvoice(_id);
    this.props.change('sale_invoices.[0].sale_invoice._id', _id )
    this.props.change('payment_method', 'cash')
    this.props.change('concept', `Pago Factura ${this.props.saleInvoice.invoice_number}`)
    // if (this.props.pristine && !!match.params._id) {
    //   this.props.loadPayment(match.params._id)
    // }
  }

  componentWillReceiveProps(nextProps) {
    this.props.change('is_consignment', nextProps.saleInvoice.is_consignment)
    this.props.change('client._id', nextProps.saleInvoice.client._id)
  }

  componentWillUnmount() {
    if (this.props.pristine) this.props.resetForm();
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, saleInvoice } = this.props;
    const { paymentMethods, paymentMethod } = this.state;

    return (
      <Modal
        open={true}
        onClose={this.props.hideModal}
        centered={false}
      >
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header as="h3" textAlign="right" content={formatCurrency(saleInvoice.subtotal.toString())} subheader="Subtotal" />
                <Header as="h3" textAlign="right" content={formatCurrency(saleInvoice.tax.toString())} subheader="Impuesto" />
                <Header as="h1" textAlign="right" content={formatCurrency(saleInvoice.total.toString())} subheader="Total" />
                <Divider />
                <Header as="h1" textAlign="right" content={formatCurrency(saleInvoice.due_amount.toString())} subheader="Saldo Pendiente" />
              </Grid.Column>
              <Grid.Column width={13}>
                <Form onSubmit={handleSubmit(this.handleSubmit)}>
                  {renderMessage(this.props)}
                  <PaymentMethodSelector paymentMethods={paymentMethods} onClick={this.onPaymentMethodClick} currentPaymentMethod={paymentMethod} />
                  <PaymentMethodFields paymentMethod={paymentMethod} />
                  <PaymentFields />
                  <Button disabled={submitting} color={this.props.color}>Grabar</Button>
                  <Button type="button" disabled={pristine || submitting} onClick={reset}>
                    Cancelar
                  </Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

PaymentForm = reduxForm({
  form: 'payment'
})(PaymentForm)

export default PaymentForm;
