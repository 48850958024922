import * as ActionTypes from '../../../actions';
import paginate from '../../../reducers/paginate';
import { combineReducers } from 'redux';

const visibilityFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  ladingBills: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_LADING_BILLS_REQUEST,
      ActionTypes.FETCH_LADING_BILLS_SUCCESS,
      ActionTypes.FETCH_LADING_BILLS_FAILURE
    ]
  }),
  ladingBill: paginate({
    mapActionToKey: action => 'ladingBill',
    types: [
      ActionTypes.FETCH_LADING_BILL_REQUEST,
      ActionTypes.FETCH_LADING_BILL_SUCCESS,
      ActionTypes.FETCH_LADING_BILL_FAILURE
    ]
  }),
  visibilityFilter
})