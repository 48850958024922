// People Culture
import React from 'react';
import DynamicImport, { Loading } from './DynamicImport';

import PeopleCulture, { PeopleCultureSidebar } from '../scenes/PeopleCulture';
// import Employees from '../scenes/PeopleCulture/Employees';
// import Departments from '../scenes/PeopleCulture/Departments';
// import JobTitles from '../scenes/PeopleCulture/JobTitles';
// import Users from '../scenes/PeopleCulture/Users';

const Employees = (props) => (
  <DynamicImport load={() => import('../scenes/PeopleCulture/Employees')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Departments = (props) => (
  <DynamicImport load={() => import('../scenes/PeopleCulture/Departments')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const JobTitles = (props) => (
  <DynamicImport load={() => import('../scenes/PeopleCulture/JobTitles')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Users = (props) => (
  <DynamicImport load={() => import('../scenes/PeopleCulture/Users')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const routes = [
  {
    path: '/personal',
    exact: true,
    sidebar: PeopleCultureSidebar,
    main: PeopleCulture
  },
  {
    path: '/personal/empleados',
    // exact: true,
    sidebar: PeopleCultureSidebar,
    main: Employees
  },
  {
    path: '/personal/departamentos',
    // exact: true,
    sidebar: PeopleCultureSidebar,
    main: Departments
  },
  {
    path: '/personal/puestos',
    // exact: true,
    sidebar: PeopleCultureSidebar,
    main: JobTitles
  },
  {
    path: '/personal/usuarios',
    // exact: true,
    sidebar: PeopleCultureSidebar,
    main: Users
  }
]

export default routes;