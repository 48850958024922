import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  printAuthorizations: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PRINT_AUTHORIZATIONS_REQUEST,
      ActionTypes.FETCH_PRINT_AUTHORIZATIONS_SUCCESS,
      ActionTypes.FETCH_PRINT_AUTHORIZATIONS_FAILURE
    ]
  }),
  printAuthorization: paginate({
    mapActionToKey: action => 'printAuthorization',
    types: [
      ActionTypes.FETCH_PRINT_AUTHORIZATION_REQUEST,
      ActionTypes.FETCH_PRINT_AUTHORIZATION_SUCCESS,
      ActionTypes.FETCH_PRINT_AUTHORIZATION_FAILURE
    ]
  })
})