import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const productCategoryFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_PRODUCT_CATEGORY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  products: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PRODUCTS_REQUEST,
      ActionTypes.FETCH_PRODUCTS_SUCCESS,
      ActionTypes.FETCH_PRODUCTS_FAILURE
    ]
  }),
  product: paginate({
    mapActionToKey: action => 'product',
    types: [
      ActionTypes.FETCH_PRODUCT_REQUEST,
      ActionTypes.FETCH_PRODUCT_SUCCESS,
      ActionTypes.FETCH_PRODUCT_FAILURE
    ]
  }),
  productMovements: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PRODUCT_MOVEMENTS_REQUEST,
      ActionTypes.FETCH_PRODUCT_MOVEMENTS_SUCCESS,
      ActionTypes.FETCH_PRODUCT_MOVEMENTS_FAILURE
    ]
  }),
  productSerials: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PRODUCT_SERIALS_REQUEST,
      ActionTypes.FETCH_PRODUCT_SERIALS_SUCCESS,
      ActionTypes.FETCH_PRODUCT_SERIALS_FAILURE
    ]
  }),
  productCategoryFilter
})