import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router'
// import { ConnectedRouter as Router, routerMiddleware } from 'react-router-redux';
// import { routerMiddleware } from 'connected-react-router';

import configureStore from './store/configureStore';

import App from './App';

// Stylesheet
// import 'react-dates/initialize';
import './semantic-ui/semantic.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/scss/App.scss';

// const browserHistory = createBrowserHistory();
const history = createBrowserHistory();
// const middleware = routerMiddleware(history);
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
