import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Dropdown, Button, Grid, Header, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import toArray from 'lodash/toArray';

import * as sort from 'sortabular';

import Table from '../../../../components/Table';
import StatusLabel from '../../SaleInvoices/components/StatusLabel';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

import { getOpenTeller } from '../../Tellers/selectors';

const formatCurrency = (value) => {
  let nf = new Intl.NumberFormat('es-419', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return "L " + nf.format(value)
}

const mapStateToProps = (state, ownProps) => {
  const {
    pagination: { tellers: { tellers: { list } } }
  } = state

  const tellersList = list || { ids: [] }

  return {
    teller: getOpenTeller(state),
    pagination: tellersList.pagination || {},
    isFetching: tellersList.isFetching || false
  };
}

class DashboardSaleInvoices extends Component {
  constructor(props) {
    super(props);

    const getSortingColumns = () => this.state.sortingColumns || {};
    const sortable = sort.sort({
      getSortingColumns,
      onSort: selectedColumn => {
        this.setState({
          sortingColumns: sort.byColumnsPrioritizeLastSorted({
            sortingColumns: this.state.sortingColumns,
            selectedColumn
          })
        });
      },
      strategy: sort.strategies.byProperty
    });

    this.state = {
      saleInvoices: [],
      sortingColumns: {
        'sold_on' : {
          direction: 'desc',
          position: 0
        },
        'is_consignment': {
          direction: 'asc',
          position: 1
        },
        'invoice_number': {
          direction: 'desc',
          position: 2
        },
      },
      columns: [
        {
          property: 'invoice_number',
          header: {
            label: 'No. Factura',
            transforms: [sortable],
            props: {
              style: {width: '12em', 'minWidth': '12em'},
              className: 'sticky-column'
            }
          },
          cell: {
            formatters: [
              (value, { rowData }) => <Link to={`ventas/facturas/${rowData._id.toString()}`}>{!rowData.isDraft && value || '-----'}</Link>
            ],
            props: {
              style: { width: '12em', 'minWidth': '12em' },
              className: 'sticky-column'
            }
          }
        },
        {
          property: 'sold_on',
          header: {
            label: 'Fecha',
            transforms: [sortable],
            props: {
              className: 'center aligned',
              style: {width: '7em'}
            }
          },
          cell: {
            formatters: [
              value => (
                value && moment(value).format('L').toString()
              )
            ],
            props: {
              className: "center aligned"
            }
          }
        },
        {
          property: 'client.name',
          header: {
            label: 'Cliente',
            transforms: [sortable],
            props: {
              className: ''
            }
          },
          cell: {
            props: {
              className: 'collapsing'
            }
          }
        },
        {
          property: 'total',
          header: {
            label: 'Total',
            transforms: [sortable],
            props: {
              className: 'currency right aligned two wide'
            }
          },
          cell: {
            formatters: [
              value => (
                value && formatCurrency(value)
              )
            ],
            props: {
              className: "right aligned"
            }
          }
        },
        {
          property: 'payments_total',
          header: {
            label: 'Pagado',
            transforms: [sortable],
            props: {
              className: 'currency right aligned two wide'
            }
          },
          cell: {
            formatters: [
              value => (
                value && formatCurrency(value)
              )
            ],
            props: {
              className: "right aligned"
            }
          }
        },
        {
          property: 'due_amount',
          header: {
            label: 'Saldo',
            transforms: [sortable],
            props: {
              className: 'currency right aligned two wide'
            }
          },
          cell: {
            formatters: [
              value => (
                value && formatCurrency(value)
              )
            ],
            props: {
              className: "right aligned"
            }
          }
        },
        {
          property: 'status',
          header: {
            label: 'Estado',
            transforms: [sortable],
            props: {
              className: 'left aligned',
              style: {
                width: '7em'
              }
            }
          },
          cell: {
            formatters: [
              value => value && StatusLabel(value)
            ],
            props: {
              className: "left aligned"
            }
          }
        },
        {
          props: {
            className: 'right aligned',
            style: {
              width: '4em'
            }
          },
          cell: {
            formatters: [
              (value, { rowData }) => (
                <Dropdown icon='ellipsis vertical' button className='icon' direction='left' >
                  <Dropdown.Menu>
                    <Dropdown.Item icon='folder open outline' as={Link} to={`ventas/facturas/${rowData._id.toString()}`} content="Ver" />
                    <Dropdown.Item icon='print' as={Link} to={`ventas/facturas/${rowData._id.toString()}`} content="Imprimir" />
                    <Dropdown.Divider />
                    <Dropdown.Item icon='payment' as={Link} to={`ventas/facturas/${rowData._id.toString()}`} content="Pagar" />
                  </Dropdown.Menu>
                </Dropdown>
              )
            ],
            props: {
              className: 'visible'
            }
          }
        }
      ]
    }
  }

  componentDidUpdate(prevProps) {
    const { teller } = this.props;

    if (teller && teller !== prevProps.teller) {
      let saleInvoices = []
       teller.sale_invoices.map(({sale_invoice}) => {
        saleInvoices.push(sale_invoice)
      })
      this.setState({saleInvoices})
    }
  }

  render() {
    const { teller, color } = this.props;
    const { columns, sortingColumns, saleInvoices } = this.state;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {teller && (
              <Table
                columns={columns}
                sortingColumns={sortingColumns}
                rows={saleInvoices}
                route="/ventas/facturas/"
                color={color}
              >
              </Table>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps, null
  )(DashboardSaleInvoices));
