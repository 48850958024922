import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from '../reducers';
import * as schema from '../schema';
import api from '../middleware/api';

const configureStore = (history) => {
  const store = createStore(
    createRootReducer(history),
    applyMiddleware(
      thunk.withExtraArgument({schema}),
      routerMiddleware(history),
      api
    )
  )

  return store;
}

export default configureStore;
