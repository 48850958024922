import * as ActionTypes from '../../../actions';
import paginate from '../../../reducers/paginate';
import { combineReducers } from 'redux';

export default combineReducers({
  report: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_REPORT_REQUEST,
      ActionTypes.FETCH_REPORT_SUCCESS,
      ActionTypes.FETCH_REPORT_FAILURE
    ]
  })
})