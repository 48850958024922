import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SidebarMenu } from '../../components/Sidebar';

const routeLinks = [
  {
    path: "/configuracion",
    name: "Configuracion",
    icon: "block layout",
    color: "red"
  },
  {
    path: "/configuracion/parametros",
    name: "Parametros",
    icon: "settings",
    color: "red"
  },
  {
    path: "/configuracion/documentos_fiscales",
    name: "Documentos Fiscales",
    icon: "calculator",
    color: "red"
  },
  {
    path: "/configuracion/establecimientos",
    name: "Establecimientos",
    icon: "money",
    color: "red"
  },
  {
    path: "/configuracion/puntos_venta",
    name: "Puntos de Venta",
    icon: "sort numeric ascending",
    color: "red"
  },
  {
    path: "/configuracion/autorizaciones_impresion",
    name: "Autorizaciones de Impresion",
    icon: "sticky note",
    color: "red"
  },
  {
    path: "/configuracion/tipos_pagos",
    name: "Condiciones de Pago",
    icon: "anchor",
    color: "red"
  },
  {
    path: "/configuracion/unidades",
    name: "Unidades de Medida",
    icon: "balance",
    color: "red"
  },
  {
    path: "/configuracion/caracteristicas",
    name: "Caracteristicas",
    icon: "cogs",
    color: "red"
  },
  {
    path: "/configuracion/categorias",
    name: "Categorias",
    icon: "list layout",
    color: "red"
  },
  {
    path: "/configuracion/bodegas",
    name: "Bodegas",
    icon: "building",
    color: "red"
  },
  {
    path: "/configuracion/tipos_precio",
    name: "Tipos Precio",
    icon: "currency",
    color: "red"
  },
  {
    path: "/configuracion/origenes_movimientos",
    name: "Origenes de Movimientos",
    icon: "map signs",
    color: "red"
  },
  {
    path: "/configuracion/tipos_movimientos",
    name: "Tipos de Movimiento",
    icon: "space shuttle",
    color: "red"
  }
]

class Settings extends Component {
  render() {
    return (
      <Grid>
        <Grid.Column>
          <Header as="h1" >Configuracion</Header>
        </Grid.Column>
      </Grid>
    )
  }
}

const SettingsSidebar = () => (
  <SidebarMenu
    items={routeLinks}

  />
)

export { SettingsSidebar };
export default Settings;
