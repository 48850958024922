import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux'
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import merge from 'lodash/merge';
// // import paginate from './paginate'

import authentication from '../scenes/Login/reducers'
import currentUser from '../scenes/User/reducers'

import characteristics from '../scenes/Settings/Characteristics/reducers'
import clients from '../scenes/Clients/Clients/reducers'
import departments from '../scenes/PeopleCulture/Departments/reducers'
import employees from '../scenes/PeopleCulture/Employees/reducers'
import jobTitles from '../scenes/PeopleCulture/JobTitles/reducers'
import priceTypes from '../scenes/Settings/PriceTypes/reducers'
import productCategories from '../scenes/Settings/ProductCategories/reducers'
import products from '../scenes/Inventory/Products/reducers'


import purchaseInvoices from '../scenes/Procurement/PurchaseInvoices/reducers'
import purchaseOrders from '../scenes/Procurement/PurchaseOrders/reducers'
import saleInvoices from '../scenes/Sales/SaleInvoices/reducers'
import payments from '../scenes/Sales/Payments/reducers'
import ladingBills from '../scenes/Sales/LadingBills/reducers'
import tellers from '../scenes/Sales/Tellers/reducers'
import quotations from '../scenes/Sales/Quotations/reducers'
import paymentTypes from '../scenes/Settings/PaymentTypes/reducers'
import units from '../scenes/Settings/Units/reducers'
import suppliers from '../scenes/Procurement/Suppliers/reducers'
import users from '../scenes/PeopleCulture/Users/reducers'
import warehouseMovements from '../scenes/Inventory/WarehouseMovements/reducers'
import inventoryCounts from '../scenes/Inventory/InventoryCounts/reducers'
import inventoryAdjustments from '../scenes/Inventory/InventoryAdjustments/reducers'
import warehouseTransfers from '../scenes/Inventory/WarehouseTransfers/reducers'
import dispatchOrders from '../scenes/Inventory/DispatchOrders/reducers'
import warehouses from '../scenes/Settings/Warehouses/reducers'
import movementTypes from '../scenes/Settings/MovementTypes/reducers'
import movementSourceTypes from '../scenes/Settings/MovementSourceTypes/reducers'

//Parameters
import parameters from '../scenes/Settings/Parameters/reducers'
import establishments from '../scenes/Settings/Establishments/reducers'
import fiscalDocuments from '../scenes/Settings/FiscalDocuments/reducers'
import printAuthorizations from '../scenes/Settings/PrintAuthorizations/reducers'
import salePoints from '../scenes/Settings/SalePoints/reducers'
import paymentMethods from '../scenes/Settings/PaymentMethods/reducers'
import report from '../scenes/Sales/AccountsReceivable/reducers'
import reports from '../scenes/Sales/Reports/reducers'

import modals from '../components/Modal/reducers'

const initialState = {
  characteristics: {},
  clients: {},
  departments: {},
  employees: {},
  jobTitles: {},
  payments: {},
  paymentTypes: {},
  units: {},
  priceTypes: {},
  productCategories: {},
  products: {},


  purchaseInvoices: {},
  purchaseOrders: {},
  saleInvoices: {},
  quotations: {},
  ladingBills: {},
  tellers: {},

  suppliers: {},
  users: {},
  warehouseMovements: {},
  inventoryCounts: {},
  inventoryAdjustments: {},
  warehouseTransfers: {},
  dispatchOrders: {},
  warehouses: {},
  parameters: {},
  establishments: {},
  fiscalDocuments: {},
  printAuthorizations: {},
  salePoints: {},
  movementTypes: {},
  movementSourceTypes: {},
  paymentMethods: {},
  report: {},
  reports: {},
  currentUser: {}
}

const entities = (state = initialState, action) => {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities)
  }

  return state
}

const pagination = combineReducers({
  characteristics,
  clients,
  departments,
  employees,
  jobTitles,
  payments,
  paymentTypes,
  units,
  priceTypes,
  productCategories,
  products,


  purchaseInvoices,
  purchaseOrders,
  saleInvoices,
  quotations,
  ladingBills,
  tellers,

  suppliers,
  users,
  warehouseMovements,
  inventoryCounts,
  inventoryAdjustments,
  warehouseTransfers,
  dispatchOrders,
  warehouses,
  parameters,
  establishments,
  fiscalDocuments,
  printAuthorizations,
  salePoints,
  movementTypes,
  movementSourceTypes,
  paymentMethods,
  report,
  reports,
  currentUser
})

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  authentication,
  entities,
  pagination,
  modals,
  form: formReducer,
  // routing: routerReducer
})

export default createRootReducer;
