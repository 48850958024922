import React, { Component } from 'react';
import 'react-dates/initialize';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';

import MainLayout from './layouts/MainLayout';
import LoginLayout from './layouts/LoginLayout';
import moment from 'moment';

require('moment/locale/es');

moment.locale('es');

// import 'react-dates/lib/css/_datepicker.css'

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path='/login' component={LoginLayout} />
        <ProtectedRoute path='/' component={MainLayout} />
      </Switch>
    );
  }
}

export default App
