import sortBy from 'lodash/sortBy';

export const getPaymentMethods = state => {
  const {
    pagination: { paymentMethods: { paymentMethods: { list } } },
    entities: { paymentMethods }
  } = state

  const paymentMethodsList = list || { ids: [] }

  return sortBy(paymentMethodsList.ids.map(id => paymentMethods[id]), ["code"])
}