import React from 'react';
import { Label } from 'semantic-ui-react';

const StatusLabel = (value, size = 'tiny') => {
  switch (value) {
    case 'PAGADA':
      return <Label horizontal size={size} content={value} color='green' />
    case 'PENDIENTE':
      return <Label horizontal size={size} content={value} color='yellow' />
    case 'VENCIDA':
      return <Label horizontal size={size} content={value} color='red' />
    case 'BORRADOR':
      return <Label horizontal size={size} content={value} color='violet' />
    case 'ANULADA':
      return <Label horizontal size={size} content={value} />
    default:
      return <Label horizontal size={size} content={value} />
  }
}

export default StatusLabel;