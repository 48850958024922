import React, { Component } from 'react';
import LoginForm from '../scenes/Login/containers/LoginFormContainer';

export default class LoginLayout extends Component {
  render() {
    return(
      <LoginForm />
    )
  }
}
