import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  characteristics: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_CHARACTERISTICS_REQUEST,
      ActionTypes.FETCH_CHARACTERISTICS_SUCCESS,
      ActionTypes.FETCH_CHARACTERISTICS_FAILURE
    ]
  }),
  characteristic: paginate({
    mapActionToKey: action => 'characteristic',
    types: [
      ActionTypes.FETCH_CHARACTERISTIC_REQUEST,
      ActionTypes.FETCH_CHARACTERISTIC_SUCCESS,
      ActionTypes.FETCH_CHARACTERISTIC_FAILURE
    ]
  })
})