// Sales
import React from 'react';
import DynamicImport, { Loading } from './DynamicImport';
import Sales, { SalesSidebar } from '../scenes/Sales';
// import SaleInvoices from '../scenes/Sales/SaleInvoices';
// import Payments from '../scenes/Sales/Payments';
// import AccountsReceivable from '../scenes/Sales/AccountsReceivable';
// import Quotations from '../scenes/Sales/Quotations';
// import LadingBills from '../scenes/Sales/LadingBills';
// import Tellers from '../scenes/Sales/Tellers';
// import Reports from '../scenes/Sales/Reports';

const SaleInvoices = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/SaleInvoices')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Payments = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/Payments')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const AccountsReceivable = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/AccountsReceivable')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Quotations = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/Quotations')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const LadingBills = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/LadingBills')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Tellers = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/Tellers')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const Reports = (props) => (
  <DynamicImport load={() => import('../scenes/Sales/Reports')}>
    {(Component) => Component === null
      ? <Loading />
      : <Component {...props} />
    }
  </DynamicImport>
)
const routes = [
  {
    path: '/ventas',
    exact: true,
    sidebar: SalesSidebar,
    main: Sales
  },
  {
    path: '/ventas/facturas',
    // exact: true,
    sidebar: SalesSidebar,
    main: SaleInvoices
  },
  {
    path: '/ventas/pagos',
    // exact: true,
    sidebar: SalesSidebar,
    main: Payments
  },
  {
    path: '/ventas/cotizaciones',
    // exact: true,
    sidebar: SalesSidebar,
    main: Quotations
  },
  {
    path: '/ventas/remisiones',
    // exact: true,
    sidebar: SalesSidebar,
    main: LadingBills
  },
  {
    path: '/ventas/cajas',
    // exact: true,
    sidebar: SalesSidebar,
    main: Tellers
  },
  {
    path: '/ventas/cuentas_por_cobrar',
    // exact: true,
    sidebar: SalesSidebar,
    main: AccountsReceivable
  },
  {
    path: '/ventas/reportes',
    // exact: true,
    sidebar: SalesSidebar,
    main: Reports
  }
]

export default routes;