import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  priceTypes: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_PRICE_TYPES_REQUEST,
      ActionTypes.FETCH_PRICE_TYPES_SUCCESS,
      ActionTypes.FETCH_PRICE_TYPES_FAILURE
    ]
  }),
  priceType: paginate({
    mapActionToKey: action => 'priceType',
    types: [
      ActionTypes.FETCH_PRICE_TYPE_REQUEST,
      ActionTypes.FETCH_PRICE_TYPE_SUCCESS,
      ActionTypes.FETCH_PRICE_TYPE_FAILURE
    ]
  })
})