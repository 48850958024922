import React from 'react';
import { NavLink } from 'react-router-dom';
// import classNames from 'classnames';
import { Dropdown, Menu, Responsive } from 'semantic-ui-react';

const MenuItems = [
  {
    color: "red",
    name: "Inicio",
    linkTo: "/",
    exact: true,
    minWidth: null
  },
  {
    color: "orange",
    name: "Clientes",
    linkTo: "/clientes",
    exact: false,
    minWidth: Responsive.onlyLargeScreen.minWidth
  },
  {
    color: "teal",
    name: "Compras",
    linkTo: "/compras",
    exact: false,
    minWidth: Responsive.onlyLargeScreen.minWidth
  },
  {
    color: "blue",
    name: "Inventarios",
    linkTo: "/inventarios",
    exact: false,
    minWidth: Responsive.onlyLargeScreen.minWidth
  },
  {
    color: "yellow",
    name: "Personal",
    linkTo: "/personal",
    exact: false,
    minWidth: Responsive.onlyLargeScreen.minWidth
  },
  {
    color: "red",
    name: "Ventas",
    linkTo: "/ventas",
    exact: false,
    minWidth: Responsive.onlyLargeScreen.minWidth
  }
]

export const DropdownMenu = (props) => {
  return (
    <Dropdown text='Secciones' pointing className='link item'>
      <Dropdown.Menu>
        {MenuItems.map((item) => (
          <Dropdown.Item key={item.name} as={NavLink} to={item.linkTo} text={item.name} color={item.color} icon="block layout" />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const InlineMenu = (props) => {
  return (
    <Menu.Menu>
      {MenuItems.map((item) => (
        <Menu.Item key={item.name} as={NavLink} activeClassName='active' exact={item.exact} to={item.linkTo} name={item.name} color={item.color}>{item.name}</Menu.Item>
      ))}
    </Menu.Menu>
  )
}

// export default SectionDropdown;
