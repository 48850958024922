import * as ActionTypes from '../../../actions'
import { payment as paymentSchema } from '../../../schema'
import { CALL_API, paginate } from '../../../middleware/api'

const fetchPayments = (params) => ({
  [CALL_API]: {
    types: [ ActionTypes.FETCH_PAYMENTS_REQUEST, ActionTypes.FETCH_PAYMENTS_SUCCESS, ActionTypes.FETCH_PAYMENTS_FAILURE ],
    endpoint: `payments`,
    schema: [paymentSchema],
    authenticated: true,
    params
  }
})

// export const loadPayments = (pagination = {}, sorting = {}, search = {}) => dispatch => {
//   const params = paginate(pagination, sorting, search)
//   return dispatch(fetchPayments(params))
// }

export const loadPayments = (params) => dispatch => {
  return dispatch(fetchPayments(paginate(params)))
}

const fetchPayment = id => ({
  [CALL_API]: {
    types: [ ActionTypes.FETCH_PAYMENT_REQUEST, ActionTypes.FETCH_PAYMENT_SUCCESS, ActionTypes.FETCH_PAYMENT_FAILURE ],
    endpoint: `payments/${id}`,
    schema: paymentSchema,
    authenticated: true,
  }
})

export const loadPayment = id => dispatch => {
  return dispatch(fetchPayment(id))
}

const createPayment = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ ActionTypes.CREATE_PAYMENT_REQUEST, ActionTypes.CREATE_PAYMENT_SUCCESS, ActionTypes.CREATE_PAYMENT_FAILURE ],
    endpoint: `payments`,
    schema: paymentSchema,
    authenticated: true,
    method: 'POST',
    data: requiredFields
  }
})

const updatePayment = (requiredFields = []) => ({
  [CALL_API]: {
    types: [ ActionTypes.UPDATE_PAYMENT_REQUEST, ActionTypes.UPDATE_PAYMENT_SUCCESS, ActionTypes.UPDATE_PAYMENT_FAILURE ],
    endpoint: `payments/${requiredFields._id}`,
    schema: paymentSchema,
    authenticated: true,
    method: 'PUT',
    data: requiredFields
  }
})

export const savePayment = (requiredFields = []) => dispatch => {
  if (requiredFields._id) {
    return dispatch(updatePayment(requiredFields))
  } else {
    return dispatch(createPayment(requiredFields))
  }
}

export const resetPayment = () => {
  return {
    type: ActionTypes.RESET_PAYMENT
  }
}

export const setVisibilityFilter = filter => {
  return {
    type: 'SET_VISIBILITY_FILTER',
    filter
  }
}
