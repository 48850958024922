import * as ActionTypes from '../../actions'
import paginate from '../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  currentUser: paginate({
    mapActionToKey: action => 'user',
    types: [
      ActionTypes.FETCH_USER_REQUEST,
      ActionTypes.FETCH_USER_SUCCESS,
      ActionTypes.FETCH_USER_FAILURE
    ]
  })
})
