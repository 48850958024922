import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

const warehouseTransferCategoryFilter = (state = 'SHOW_ALL', action) => {
  switch (action.type) {
    case 'SET_WAREHOUSE_TRANSFER_CATEGORY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  warehouseTransfers: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_WAREHOUSE_TRANSFERS_REQUEST,
      ActionTypes.FETCH_WAREHOUSE_TRANSFERS_SUCCESS,
      ActionTypes.FETCH_WAREHOUSE_TRANSFERS_FAILURE
    ]
  }),
  warehouseTransfer: paginate({
    mapActionToKey: action => 'warehouseTransfer',
    types: [
      ActionTypes.FETCH_WAREHOUSE_TRANSFER_REQUEST,
      ActionTypes.FETCH_WAREHOUSE_TRANSFER_SUCCESS,
      ActionTypes.FETCH_WAREHOUSE_TRANSFER_FAILURE
    ]
  }),
  warehouseTransferCategoryFilter
})