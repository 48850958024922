import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import omit from 'lodash/omit'

import { Form } from 'semantic-ui-react'

import { SingleDatePicker, SingleDatePickerShape, SingleDatePickerPhrases, isInclusivelyBeforeDay, HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from 'react-dates'

moment.locale('es');

const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,

  ...omit(SingleDatePickerShape, [
    'date',
    'onDateChange',
    'focused',
    'onFocusChange',
  ]),
}

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,

  // input related props
  id: 'date',
  placeholder: 'Fecha',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDate: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  block: false,
  noBorder: true,
  small: false,
  regular: false,
  verticalSpacing: 0,

  // calendar presentation and interaction related props
  renderMonth: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDate: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() { },
  onNextMonthClick() { },

  // day presentation and interaction related props
  renderDay: null,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyBeforeDay(day, moment()),
  isDayHighlighted: () => { },

  // internationalization props
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',
  phrases: SingleDatePickerPhrases,
}

class SingleDatePickerWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: props.autoFocus,
      date: props.input.value ? moment(props.input.value) : props.initialDate,
    }

    this.onDateChange = this.onDateChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
  }

  onDateChange(date) {
    const { input: { onChange } } = this.props

    this.setState({ date })
    onChange(date)
  }

  onFocusChange({ focused }) {
    this.setState({ focused })
  }

  componentWillReceiveProps(nextProps) {
    const { input: { value, onChange } } = nextProps

    this.setState({ date: (!value || value === '') ? null : moment(value) })
    // onChange(value)
  }

  render() {
    const { focused, date } = this.state
    const { label, placeholder, input: { name, value }, meta: { error }, ...restProps } = this.props

    // autoFocus and initialDate are helper props for the example wrapper but are not
    // props on the SingleDatePicker itself and thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
      'initialDate',
      'input',
      'type',
      'meta',
      'label'
    ])

    return (
      <Form.Field>
        <label>{label}</label>
        <SingleDatePicker
          {...props}
          id={name}
          placeholder={label}
          date={date}
          focused={focused}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
        />
      </Form.Field>
    )
  }
}

SingleDatePickerWrapper.propTypes = propTypes
SingleDatePickerWrapper.defaultProps = defaultProps

export default SingleDatePickerWrapper