// MODAL

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// Authentication

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// USER

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const RESET_USER = 'RESET_USER'

// CLIENT

export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const FETCH_CLIENT_REQUEST = 'FETCH_CLIENT_REQUEST'
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS'
export const FETCH_CLIENT_FAILURE = 'FETCH_CLIENT_FAILURE'

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE'

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE'

export const RESET_CLIENT = 'RESET_CLIENT'

// SALE_CONSIGNMENT

export const FETCH_SALE_CONSIGNMENTS_REQUEST = 'FETCH_SALE_CONSIGNMENTS_REQUEST'
export const FETCH_SALE_CONSIGNMENTS_SUCCESS = 'FETCH_SALE_CONSIGNMENTS_SUCCESS'
export const FETCH_SALE_CONSIGNMENTS_FAILURE = 'FETCH_SALE_CONSIGNMENTS_FAILURE'

export const FETCH_SALE_CONSIGNMENT_REQUEST = 'FETCH_SALE_CONSIGNMENT_REQUEST'
export const FETCH_SALE_CONSIGNMENT_SUCCESS = 'FETCH_SALE_CONSIGNMENT_SUCCESS'
export const FETCH_SALE_CONSIGNMENT_FAILURE = 'FETCH_SALE_CONSIGNMENT_FAILURE'

export const CREATE_SALE_CONSIGNMENT_REQUEST = 'CREATE_SALE_CONSIGNMENT_REQUEST'
export const CREATE_SALE_CONSIGNMENT_SUCCESS = 'CREATE_SALE_CONSIGNMENT_SUCCESS'
export const CREATE_SALE_CONSIGNMENT_FAILURE = 'CREATE_SALE_CONSIGNMENT_FAILURE'

export const UPDATE_SALE_CONSIGNMENT_REQUEST = 'UPDATE_SALE_CONSIGNMENT_REQUEST'
export const UPDATE_SALE_CONSIGNMENT_SUCCESS = 'UPDATE_SALE_CONSIGNMENT_SUCCESS'
export const UPDATE_SALE_CONSIGNMENT_FAILURE = 'UPDATE_SALE_CONSIGNMENT_FAILURE'

export const RESET_SALE_CONSIGNMENT = 'RESET_SALE_CONSIGNMENT'

// CHARACTERISTIC

export const FETCH_CHARACTERISTICS_REQUEST = 'FETCH_CHARACTERISTICS_REQUEST';
export const FETCH_CHARACTERISTICS_SUCCESS = 'FETCH_CHARACTERISTICS_SUCCESS';
export const FETCH_CHARACTERISTICS_FAILURE = 'FETCH_CHARACTERISTICS_FAILURE';

export const CREATE_CHARACTERISTIC_REQUEST = 'CREATE_CHARACTERISTIC_REQUEST';
export const CREATE_CHARACTERISTIC_SUCCESS = 'CREATE_CHARACTERISTIC_SUCCESS';
export const CREATE_CHARACTERISTIC_FAILURE = 'CREATE_CHARACTERISTIC_FAILURE';

export const UPDATE_CHARACTERISTIC_REQUEST = 'UPDATE_CHARACTERISTIC_REQUEST';
export const UPDATE_CHARACTERISTIC_SUCCESS = 'UPDATE_CHARACTERISTIC_SUCCESS';
export const UPDATE_CHARACTERISTIC_FAILURE = 'UPDATE_CHARACTERISTIC_FAILURE';

export const FETCH_CHARACTERISTIC_REQUEST = 'FETCH_CHARACTERISTIC_REQUEST';
export const FETCH_CHARACTERISTIC_SUCCESS = 'FETCH_CHARACTERISTIC_SUCCESS';
export const FETCH_CHARACTERISTIC_FAILURE = 'FETCH_CHARACTERISTIC_FAILURE';

export const RESET_CHARACTERISTIC = 'RESET_CHARACTERISTIC';

// PRICE_TYPE

export const FETCH_PRICE_TYPES_REQUEST = 'FETCH_PRICE_TYPES_REQUEST';
export const FETCH_PRICE_TYPES_SUCCESS = 'FETCH_PRICE_TYPES_SUCCESS';
export const FETCH_PRICE_TYPES_FAILURE = 'FETCH_PRICE_TYPES_FAILURE';

export const CREATE_PRICE_TYPE_REQUEST = 'CREATE_PRICE_TYPE_REQUEST';
export const CREATE_PRICE_TYPE_SUCCESS = 'CREATE_PRICE_TYPE_SUCCESS';
export const CREATE_PRICE_TYPE_FAILURE = 'CREATE_PRICE_TYPE_FAILURE';

export const UPDATE_PRICE_TYPE_REQUEST = 'UPDATE_PRICE_TYPE_REQUEST';
export const UPDATE_PRICE_TYPE_SUCCESS = 'UPDATE_PRICE_TYPE_SUCCESS';
export const UPDATE_PRICE_TYPE_FAILURE = 'UPDATE_PRICE_TYPE_FAILURE';

export const FETCH_PRICE_TYPE_REQUEST = 'FETCH_PRICE_TYPE_REQUEST';
export const FETCH_PRICE_TYPE_SUCCESS = 'FETCH_PRICE_TYPE_SUCCESS';
export const FETCH_PRICE_TYPE_FAILURE = 'FETCH_PRICE_TYPE_FAILURE';

export const RESET_PRICE_TYPE = 'RESET_PRICE_TYPE';

// MOVEMENT_TYPE

export const FETCH_MOVEMENT_TYPES_REQUEST = 'FETCH_MOVEMENT_TYPES_REQUEST';
export const FETCH_MOVEMENT_TYPES_SUCCESS = 'FETCH_MOVEMENT_TYPES_SUCCESS';
export const FETCH_MOVEMENT_TYPES_FAILURE = 'FETCH_MOVEMENT_TYPES_FAILURE';

export const CREATE_MOVEMENT_TYPE_REQUEST = 'CREATE_MOVEMENT_TYPE_REQUEST';
export const CREATE_MOVEMENT_TYPE_SUCCESS = 'CREATE_MOVEMENT_TYPE_SUCCESS';
export const CREATE_MOVEMENT_TYPE_FAILURE = 'CREATE_MOVEMENT_TYPE_FAILURE';

export const UPDATE_MOVEMENT_TYPE_REQUEST = 'UPDATE_MOVEMENT_TYPE_REQUEST';
export const UPDATE_MOVEMENT_TYPE_SUCCESS = 'UPDATE_MOVEMENT_TYPE_SUCCESS';
export const UPDATE_MOVEMENT_TYPE_FAILURE = 'UPDATE_MOVEMENT_TYPE_FAILURE';

export const FETCH_MOVEMENT_TYPE_REQUEST = 'FETCH_MOVEMENT_TYPE_REQUEST';
export const FETCH_MOVEMENT_TYPE_SUCCESS = 'FETCH_MOVEMENT_TYPE_SUCCESS';
export const FETCH_MOVEMENT_TYPE_FAILURE = 'FETCH_MOVEMENT_TYPE_FAILURE';

export const RESET_MOVEMENT_TYPE = 'RESET_MOVEMENT_TYPE';

// MOVEMENT_SOURCE_TYPE

export const FETCH_MOVEMENT_SOURCE_TYPES_REQUEST = 'FETCH_MOVEMENT_SOURCE_TYPES_REQUEST';
export const FETCH_MOVEMENT_SOURCE_TYPES_SUCCESS = 'FETCH_MOVEMENT_SOURCE_TYPES_SUCCESS';
export const FETCH_MOVEMENT_SOURCE_TYPES_FAILURE = 'FETCH_MOVEMENT_SOURCE_TYPES_FAILURE';

export const CREATE_MOVEMENT_SOURCE_TYPE_REQUEST = 'CREATE_MOVEMENT_SOURCE_TYPE_REQUEST';
export const CREATE_MOVEMENT_SOURCE_TYPE_SUCCESS = 'CREATE_MOVEMENT_SOURCE_TYPE_SUCCESS';
export const CREATE_MOVEMENT_SOURCE_TYPE_FAILURE = 'CREATE_MOVEMENT_SOURCE_TYPE_FAILURE';

export const UPDATE_MOVEMENT_SOURCE_TYPE_REQUEST = 'UPDATE_MOVEMENT_SOURCE_TYPE_REQUEST';
export const UPDATE_MOVEMENT_SOURCE_TYPE_SUCCESS = 'UPDATE_MOVEMENT_SOURCE_TYPE_SUCCESS';
export const UPDATE_MOVEMENT_SOURCE_TYPE_FAILURE = 'UPDATE_MOVEMENT_SOURCE_TYPE_FAILURE';

export const FETCH_MOVEMENT_SOURCE_TYPE_REQUEST = 'FETCH_MOVEMENT_SOURCE_TYPE_REQUEST';
export const FETCH_MOVEMENT_SOURCE_TYPE_SUCCESS = 'FETCH_MOVEMENT_SOURCE_TYPE_SUCCESS';
export const FETCH_MOVEMENT_SOURCE_TYPE_FAILURE = 'FETCH_MOVEMENT_SOURCE_TYPE_FAILURE';

export const RESET_MOVEMENT_SOURCE_TYPE = 'RESET_MOVEMENT_SOURCE_TYPE';

// PRODUCT_CATEGORY

export const FETCH_PRODUCT_CATEGORIES_REQUEST = 'FETCH_PRODUCT_CATEGORIES_REQUEST';
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_PRODUCT_CATEGORIES_FAILURE = 'FETCH_PRODUCT_CATEGORIES_FAILURE';

export const CREATE_PRODUCT_CATEGORY_REQUEST = 'CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE';

export const UPDATE_PRODUCT_CATEGORY_REQUEST = 'UPDATE_PRODUCT_CATEGORY_REQUEST';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS';
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'UPDATE_PRODUCT_CATEGORY_FAILURE';

export const FETCH_PRODUCT_CATEGORY_REQUEST = 'FETCH_PRODUCT_CATEGORY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_FAILURE = 'FETCH_PRODUCT_CATEGORY_FAILURE';

export const RESET_PRODUCT_CATEGORY = 'RESET_PRODUCT_CATEGORY';

// PRODUCT

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const RESET_PRODUCT = 'RESET_PRODUCT';

// BAND

export const FETCH_BANDS_REQUEST = 'FETCH_BANDS_REQUEST';
export const FETCH_BANDS_SUCCESS = 'FETCH_BANDS_SUCCESS';
export const FETCH_BANDS_FAILURE = 'FETCH_BANDS_FAILURE';

export const CREATE_BAND_REQUEST = 'CREATE_BAND_REQUEST';
export const CREATE_BAND_SUCCESS = 'CREATE_BAND_SUCCESS';
export const CREATE_BAND_FAILURE = 'CREATE_BAND_FAILURE';

export const UPDATE_BAND_REQUEST = 'UPDATE_BAND_REQUEST';
export const UPDATE_BAND_SUCCESS = 'UPDATE_BAND_SUCCESS';
export const UPDATE_BAND_FAILURE = 'UPDATE_BAND_FAILURE';

export const FETCH_BAND_REQUEST = 'FETCH_BAND_REQUEST';
export const FETCH_BAND_SUCCESS = 'FETCH_BAND_SUCCESS';
export const FETCH_BAND_FAILURE = 'FETCH_BAND_FAILURE';

export const RESET_BAND = 'RESET_BAND';

// CASING

export const FETCH_CASINGS_REQUEST = 'FETCH_CASINGS_REQUEST';
export const FETCH_CASINGS_SUCCESS = 'FETCH_CASINGS_SUCCESS';
export const FETCH_CASINGS_FAILURE = 'FETCH_CASINGS_FAILURE';

export const CREATE_CASING_REQUEST = 'CREATE_CASING_REQUEST';
export const CREATE_CASING_SUCCESS = 'CREATE_CASING_SUCCESS';
export const CREATE_CASING_FAILURE = 'CREATE_CASING_FAILURE';

export const UPDATE_CASING_REQUEST = 'UPDATE_CASING_REQUEST';
export const UPDATE_CASING_SUCCESS = 'UPDATE_CASING_SUCCESS';
export const UPDATE_CASING_FAILURE = 'UPDATE_CASING_FAILURE';

export const FETCH_CASING_REQUEST = 'FETCH_CASING_REQUEST';
export const FETCH_CASING_SUCCESS = 'FETCH_CASING_SUCCESS';
export const FETCH_CASING_FAILURE = 'FETCH_CASING_FAILURE';

export const RESET_CASING = 'RESET_CASING';

// PRODUCT_MOVEMENT

export const FETCH_PRODUCT_MOVEMENTS_REQUEST = 'FETCH_PRODUCT_MOVEMENTS_REQUEST';
export const FETCH_PRODUCT_MOVEMENTS_SUCCESS = 'FETCH_PRODUCT_MOVEMENTS_SUCCESS';
export const FETCH_PRODUCT_MOVEMENTS_FAILURE = 'FETCH_PRODUCT_MOVEMENTS_FAILURE';

export const FETCH_PRODUCT_MOVEMENT_REQUEST = 'FETCH_PRODUCT_MOVEMENT_REQUEST';
export const FETCH_PRODUCT_MOVEMENT_SUCCESS = 'FETCH_PRODUCT_MOVEMENT_SUCCESS';
export const FETCH_PRODUCT_MOVEMENT_FAILURE = 'FETCH_PRODUCT_MOVEMENT_FAILURE';

export const RESET_PRODUCT_MOVEMENT = 'RESET_PRODUCT_MOVEMENT';

// PRODUCT_SERIAL

export const FETCH_PRODUCT_SERIALS_REQUEST = 'FETCH_PRODUCT_SERIALS_REQUEST';
export const FETCH_PRODUCT_SERIALS_SUCCESS = 'FETCH_PRODUCT_SERIALS_SUCCESS';
export const FETCH_PRODUCT_SERIALS_FAILURE = 'FETCH_PRODUCT_SERIALS_FAILURE';

export const FETCH_PRODUCT_SERIAL_REQUEST = 'FETCH_PRODUCT_SERIAL_REQUEST';
export const FETCH_PRODUCT_SERIAL_SUCCESS = 'FETCH_PRODUCT_SERIAL_SUCCESS';
export const FETCH_PRODUCT_SERIAL_FAILURE = 'FETCH_PRODUCT_SERIAL_FAILURE';

export const RESET_PRODUCT_SERIAL = 'RESET_PRODUCT_SERIAL';

// SUBPRODUCT

export const FETCH_SUBPRODUCTS_REQUEST = 'FETCH_SUBPRODUCTS_REQUEST';
export const FETCH_SUBPRODUCTS_SUCCESS = 'FETCH_SUBPRODUCTS_SUCCESS';
export const FETCH_SUBPRODUCTS_FAILURE = 'FETCH_SUBPRODUCTS_FAILURE';

export const CREATE_SUBPRODUCT_REQUEST = 'CREATE_SUBPRODUCT_REQUEST';
export const CREATE_SUBPRODUCT_SUCCESS = 'CREATE_SUBPRODUCT_SUCCESS';
export const CREATE_SUBPRODUCT_FAILURE = 'CREATE_SUBPRODUCT_FAILURE';

export const UPDATE_SUBPRODUCT_REQUEST = 'UPDATE_SUBPRODUCT_REQUEST';
export const UPDATE_SUBPRODUCT_SUCCESS = 'UPDATE_SUBPRODUCT_SUCCESS';
export const UPDATE_SUBPRODUCT_FAILURE = 'UPDATE_SUBPRODUCT_FAILURE';

export const FETCH_SUBPRODUCT_REQUEST = 'FETCH_SUBPRODUCT_REQUEST';
export const FETCH_SUBPRODUCT_SUCCESS = 'FETCH_SUBPRODUCT_SUCCESS';
export const FETCH_SUBPRODUCT_FAILURE = 'FETCH_SUBPRODUCT_FAILURE';

export const RESET_SUBPRODUCT = 'RESET_SUBPRODUCT';

// WAREHOUSE_MOVEMENT

export const FETCH_WAREHOUSE_MOVEMENTS_REQUEST = 'FETCH_WAREHOUSE_MOVEMENTS_REQUEST';
export const FETCH_WAREHOUSE_MOVEMENTS_SUCCESS = 'FETCH_WAREHOUSE_MOVEMENTS_SUCCESS';
export const FETCH_WAREHOUSE_MOVEMENTS_FAILURE = 'FETCH_WAREHOUSE_MOVEMENTS_FAILURE';

export const CREATE_WAREHOUSE_MOVEMENT_REQUEST = 'CREATE_WAREHOUSE_MOVEMENT_REQUEST';
export const CREATE_WAREHOUSE_MOVEMENT_SUCCESS = 'CREATE_WAREHOUSE_MOVEMENT_SUCCESS';
export const CREATE_WAREHOUSE_MOVEMENT_FAILURE = 'CREATE_WAREHOUSE_MOVEMENT_FAILURE';

export const UPDATE_WAREHOUSE_MOVEMENT_REQUEST = 'UPDATE_WAREHOUSE_MOVEMENT_REQUEST';
export const UPDATE_WAREHOUSE_MOVEMENT_SUCCESS = 'UPDATE_WAREHOUSE_MOVEMENT_SUCCESS';
export const UPDATE_WAREHOUSE_MOVEMENT_FAILURE = 'UPDATE_WAREHOUSE_MOVEMENT_FAILURE';

export const FETCH_WAREHOUSE_MOVEMENT_REQUEST = 'FETCH_WAREHOUSE_MOVEMENT_REQUEST';
export const FETCH_WAREHOUSE_MOVEMENT_SUCCESS = 'FETCH_WAREHOUSE_MOVEMENT_SUCCESS';
export const FETCH_WAREHOUSE_MOVEMENT_FAILURE = 'FETCH_WAREHOUSE_MOVEMENT_FAILURE';

export const RESET_WAREHOUSE_MOVEMENT = 'RESET_WAREHOUSE_MOVEMENT';

// DISPATCH_ORDER

export const FETCH_DISPATCH_ORDERS_REQUEST = 'FETCH_DISPATCH_ORDERS_REQUEST';
export const FETCH_DISPATCH_ORDERS_SUCCESS = 'FETCH_DISPATCH_ORDERS_SUCCESS';
export const FETCH_DISPATCH_ORDERS_FAILURE = 'FETCH_DISPATCH_ORDERS_FAILURE';

export const CREATE_DISPATCH_ORDER_REQUEST = 'CREATE_DISPATCH_ORDER_REQUEST';
export const CREATE_DISPATCH_ORDER_SUCCESS = 'CREATE_DISPATCH_ORDER_SUCCESS';
export const CREATE_DISPATCH_ORDER_FAILURE = 'CREATE_DISPATCH_ORDER_FAILURE';

export const UPDATE_DISPATCH_ORDER_REQUEST = 'UPDATE_DISPATCH_ORDER_REQUEST';
export const UPDATE_DISPATCH_ORDER_SUCCESS = 'UPDATE_DISPATCH_ORDER_SUCCESS';
export const UPDATE_DISPATCH_ORDER_FAILURE = 'UPDATE_DISPATCH_ORDER_FAILURE';

export const FETCH_DISPATCH_ORDER_REQUEST = 'FETCH_DISPATCH_ORDER_REQUEST';
export const FETCH_DISPATCH_ORDER_SUCCESS = 'FETCH_DISPATCH_ORDER_SUCCESS';
export const FETCH_DISPATCH_ORDER_FAILURE = 'FETCH_DISPATCH_ORDER_FAILURE';

export const RESET_DISPATCH_ORDER = 'RESET_DISPATCH_ORDER';

// INVENTORY_COUNT

export const FETCH_INVENTORY_COUNTS_REQUEST = 'FETCH_INVENTORY_COUNTS_REQUEST';
export const FETCH_INVENTORY_COUNTS_SUCCESS = 'FETCH_INVENTORY_COUNTS_SUCCESS';
export const FETCH_INVENTORY_COUNTS_FAILURE = 'FETCH_INVENTORY_COUNTS_FAILURE';

export const CREATE_INVENTORY_COUNT_REQUEST = 'CREATE_INVENTORY_COUNT_REQUEST';
export const CREATE_INVENTORY_COUNT_SUCCESS = 'CREATE_INVENTORY_COUNT_SUCCESS';
export const CREATE_INVENTORY_COUNT_FAILURE = 'CREATE_INVENTORY_COUNT_FAILURE';

export const UPDATE_INVENTORY_COUNT_REQUEST = 'UPDATE_INVENTORY_COUNT_REQUEST';
export const UPDATE_INVENTORY_COUNT_SUCCESS = 'UPDATE_INVENTORY_COUNT_SUCCESS';
export const UPDATE_INVENTORY_COUNT_FAILURE = 'UPDATE_INVENTORY_COUNT_FAILURE';

export const FETCH_INVENTORY_COUNT_REQUEST = 'FETCH_INVENTORY_COUNT_REQUEST';
export const FETCH_INVENTORY_COUNT_SUCCESS = 'FETCH_INVENTORY_COUNT_SUCCESS';
export const FETCH_INVENTORY_COUNT_FAILURE = 'FETCH_INVENTORY_COUNT_FAILURE';

export const RESET_INVENTORY_COUNT = 'RESET_INVENTORY_COUNT';

// INVENTORY_ADJUSTMENT

export const FETCH_INVENTORY_ADJUSTMENTS_REQUEST = 'FETCH_INVENTORY_ADJUSTMENTS_REQUEST';
export const FETCH_INVENTORY_ADJUSTMENTS_SUCCESS = 'FETCH_INVENTORY_ADJUSTMENTS_SUCCESS';
export const FETCH_INVENTORY_ADJUSTMENTS_FAILURE = 'FETCH_INVENTORY_ADJUSTMENTS_FAILURE';

export const CREATE_INVENTORY_ADJUSTMENT_REQUEST = 'CREATE_INVENTORY_ADJUSTMENT_REQUEST';
export const CREATE_INVENTORY_ADJUSTMENT_SUCCESS = 'CREATE_INVENTORY_ADJUSTMENT_SUCCESS';
export const CREATE_INVENTORY_ADJUSTMENT_FAILURE = 'CREATE_INVENTORY_ADJUSTMENT_FAILURE';

export const UPDATE_INVENTORY_ADJUSTMENT_REQUEST = 'UPDATE_INVENTORY_ADJUSTMENT_REQUEST';
export const UPDATE_INVENTORY_ADJUSTMENT_SUCCESS = 'UPDATE_INVENTORY_ADJUSTMENT_SUCCESS';
export const UPDATE_INVENTORY_ADJUSTMENT_FAILURE = 'UPDATE_INVENTORY_ADJUSTMENT_FAILURE';

export const FETCH_INVENTORY_ADJUSTMENT_REQUEST = 'FETCH_INVENTORY_ADJUSTMENT_REQUEST';
export const FETCH_INVENTORY_ADJUSTMENT_SUCCESS = 'FETCH_INVENTORY_ADJUSTMENT_SUCCESS';
export const FETCH_INVENTORY_ADJUSTMENT_FAILURE = 'FETCH_INVENTORY_ADJUSTMENT_FAILURE';

export const RESET_INVENTORY_ADJUSTMENT = 'RESET_INVENTORY_ADJUSTMENT';

// WAREHOUSE_TRANSFER

export const FETCH_WAREHOUSE_TRANSFERS_REQUEST = 'FETCH_WAREHOUSE_TRANSFERS_REQUEST';
export const FETCH_WAREHOUSE_TRANSFERS_SUCCESS = 'FETCH_WAREHOUSE_TRANSFERS_SUCCESS';
export const FETCH_WAREHOUSE_TRANSFERS_FAILURE = 'FETCH_WAREHOUSE_TRANSFERS_FAILURE';

export const CREATE_WAREHOUSE_TRANSFER_REQUEST = 'CREATE_WAREHOUSE_TRANSFER_REQUEST';
export const CREATE_WAREHOUSE_TRANSFER_SUCCESS = 'CREATE_WAREHOUSE_TRANSFER_SUCCESS';
export const CREATE_WAREHOUSE_TRANSFER_FAILURE = 'CREATE_WAREHOUSE_TRANSFER_FAILURE';

export const UPDATE_WAREHOUSE_TRANSFER_REQUEST = 'UPDATE_WAREHOUSE_TRANSFER_REQUEST';
export const UPDATE_WAREHOUSE_TRANSFER_SUCCESS = 'UPDATE_WAREHOUSE_TRANSFER_SUCCESS';
export const UPDATE_WAREHOUSE_TRANSFER_FAILURE = 'UPDATE_WAREHOUSE_TRANSFER_FAILURE';

export const FETCH_WAREHOUSE_TRANSFER_REQUEST = 'FETCH_WAREHOUSE_TRANSFER_REQUEST';
export const FETCH_WAREHOUSE_TRANSFER_SUCCESS = 'FETCH_WAREHOUSE_TRANSFER_SUCCESS';
export const FETCH_WAREHOUSE_TRANSFER_FAILURE = 'FETCH_WAREHOUSE_TRANSFER_FAILURE';

export const RESET_WAREHOUSE_TRANSFER = 'RESET_WAREHOUSE_TRANSFER';

// WAREHOUSE

export const FETCH_WAREHOUSES_REQUEST = 'FETCH_WAREHOUSES_REQUEST';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_FAILURE = 'FETCH_WAREHOUSES_FAILURE';

export const CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILURE = 'CREATE_WAREHOUSE_FAILURE';

export const UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILURE = 'UPDATE_WAREHOUSE_FAILURE';

export const FETCH_WAREHOUSE_REQUEST = 'FETCH_WAREHOUSE_REQUEST';
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS';
export const FETCH_WAREHOUSE_FAILURE = 'FETCH_WAREHOUSE_FAILURE';

export const RESET_WAREHOUSE = 'RESET_WAREHOUSE';

// DEPARTMENT

export const FETCH_DEPARTMENTS_REQUEST = 'FETCH_DEPARTMENTS_REQUEST';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE';

export const CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAILURE = 'CREATE_DEPARTMENT_FAILURE';

export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILURE = 'UPDATE_DEPARTMENT_FAILURE';

export const FETCH_DEPARTMENT_REQUEST = 'FETCH_DEPARTMENT_REQUEST';
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS';
export const FETCH_DEPARTMENT_FAILURE = 'FETCH_DEPARTMENT_FAILURE';

export const RESET_DEPARTMENT = 'RESET_DEPARTMENT';

// EMPLOYEE

export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';

export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const FETCH_EMPLOYEE_REQUEST = 'FETCH_EMPLOYEE_REQUEST';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';

export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';

// JOB_TITLE

export const FETCH_JOB_TITLES_REQUEST = 'FETCH_JOB_TITLES_REQUEST';
export const FETCH_JOB_TITLES_SUCCESS = 'FETCH_JOB_TITLES_SUCCESS';
export const FETCH_JOB_TITLES_FAILURE = 'FETCH_JOB_TITLES_FAILURE';

export const CREATE_JOB_TITLE_REQUEST = 'CREATE_JOB_TITLE_REQUEST';
export const CREATE_JOB_TITLE_SUCCESS = 'CREATE_JOB_TITLE_SUCCESS';
export const CREATE_JOB_TITLE_FAILURE = 'CREATE_JOB_TITLE_FAILURE';

export const UPDATE_JOB_TITLE_REQUEST = 'UPDATE_JOB_TITLE_REQUEST';
export const UPDATE_JOB_TITLE_SUCCESS = 'UPDATE_JOB_TITLE_SUCCESS';
export const UPDATE_JOB_TITLE_FAILURE = 'UPDATE_JOB_TITLE_FAILURE';

export const FETCH_JOB_TITLE_REQUEST = 'FETCH_JOB_TITLE_REQUEST';
export const FETCH_JOB_TITLE_SUCCESS = 'FETCH_JOB_TITLE_SUCCESS';
export const FETCH_JOB_TITLE_FAILURE = 'FETCH_JOB_TITLE_FAILURE';

export const RESET_JOB_TITLE = 'RESET_JOB_TITLE';

// PAYMENT

export const FETCH_PAYMENTS_REQUEST = 'FETCH_PAYMENTS_REQUEST';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE'

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';

export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';

export const FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_FAILURE = 'FETCH_PAYMENT_FAILURE';

export const RESET_PAYMENT = 'RESET_PAYMENT';

// QUOTATION

export const FETCH_QUOTATIONS_REQUEST = 'FETCH_QUOTATIONS_REQUEST';
export const FETCH_QUOTATIONS_SUCCESS = 'FETCH_QUOTATIONS_SUCCESS';
export const FETCH_QUOTATIONS_FAILURE = 'FETCH_QUOTATIONS_FAILURE'

export const CREATE_QUOTATION_REQUEST = 'CREATE_QUOTATION_REQUEST';
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE';

export const UPDATE_QUOTATION_REQUEST = 'UPDATE_QUOTATION_REQUEST';
export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS';
export const UPDATE_QUOTATION_FAILURE = 'UPDATE_QUOTATION_FAILURE';

export const FETCH_QUOTATION_REQUEST = 'FETCH_QUOTATION_REQUEST';
export const FETCH_QUOTATION_SUCCESS = 'FETCH_QUOTATION_SUCCESS';
export const FETCH_QUOTATION_FAILURE = 'FETCH_QUOTATION_FAILURE';

export const RESET_QUOTATION = 'RESET_QUOTATION';

// LADING_BILL

export const FETCH_LADING_BILLS_REQUEST = 'FETCH_LADING_BILLS_REQUEST';
export const FETCH_LADING_BILLS_SUCCESS = 'FETCH_LADING_BILLS_SUCCESS';
export const FETCH_LADING_BILLS_FAILURE = 'FETCH_LADING_BILLS_FAILURE'

export const CREATE_LADING_BILL_REQUEST = 'CREATE_LADING_BILL_REQUEST';
export const CREATE_LADING_BILL_SUCCESS = 'CREATE_LADING_BILL_SUCCESS';
export const CREATE_LADING_BILL_FAILURE = 'CREATE_LADING_BILL_FAILURE';

export const UPDATE_LADING_BILL_REQUEST = 'UPDATE_LADING_BILL_REQUEST';
export const UPDATE_LADING_BILL_SUCCESS = 'UPDATE_LADING_BILL_SUCCESS';
export const UPDATE_LADING_BILL_FAILURE = 'UPDATE_LADING_BILL_FAILURE';

export const FETCH_LADING_BILL_REQUEST = 'FETCH_LADING_BILL_REQUEST';
export const FETCH_LADING_BILL_SUCCESS = 'FETCH_LADING_BILL_SUCCESS';
export const FETCH_LADING_BILL_FAILURE = 'FETCH_LADING_BILL_FAILURE';

export const RESET_LADING_BILL = 'RESET_LADING_BILL';

// TELLER

export const FETCH_TELLERS_REQUEST = 'FETCH_TELLERS_REQUEST';
export const FETCH_TELLERS_SUCCESS = 'FETCH_TELLERS_SUCCESS';
export const FETCH_TELLERS_FAILURE = 'FETCH_TELLERS_FAILURE'

export const CREATE_TELLER_REQUEST = 'CREATE_TELLER_REQUEST';
export const CREATE_TELLER_SUCCESS = 'CREATE_TELLER_SUCCESS';
export const CREATE_TELLER_FAILURE = 'CREATE_TELLER_FAILURE';

export const UPDATE_TELLER_REQUEST = 'UPDATE_TELLER_REQUEST';
export const UPDATE_TELLER_SUCCESS = 'UPDATE_TELLER_SUCCESS';
export const UPDATE_TELLER_FAILURE = 'UPDATE_TELLER_FAILURE';

export const FETCH_TELLER_REQUEST = 'FETCH_TELLER_REQUEST';
export const FETCH_TELLER_SUCCESS = 'FETCH_TELLER_SUCCESS';
export const FETCH_TELLER_FAILURE = 'FETCH_TELLER_FAILURE';

export const RESET_TELLER = 'RESET_TELLER';

// PAYMENT_TYPE

export const FETCH_PAYMENT_TYPES_REQUEST = 'FETCH_PAYMENT_TYPES_REQUEST';
export const FETCH_PAYMENT_TYPES_SUCCESS = 'FETCH_PAYMENT_TYPES_SUCCESS';
export const FETCH_PAYMENT_TYPES_FAILURE = 'FETCH_PAYMENT_TYPES_FAILURE'

export const CREATE_PAYMENT_TYPE_REQUEST = 'CREATE_PAYMENT_TYPE_REQUEST';
export const CREATE_PAYMENT_TYPE_SUCCESS = 'CREATE_PAYMENT_TYPE_SUCCESS';
export const CREATE_PAYMENT_TYPE_FAILURE = 'CREATE_PAYMENT_TYPE_FAILURE';

export const UPDATE_PAYMENT_TYPE_REQUEST = 'UPDATE_PAYMENT_TYPE_REQUEST';
export const UPDATE_PAYMENT_TYPE_SUCCESS = 'UPDATE_PAYMENT_TYPE_SUCCESS';
export const UPDATE_PAYMENT_TYPE_FAILURE = 'UPDATE_PAYMENT_TYPE_FAILURE';

export const FETCH_PAYMENT_TYPE_REQUEST = 'FETCH_PAYMENT_TYPE_REQUEST';
export const FETCH_PAYMENT_TYPE_SUCCESS = 'FETCH_PAYMENT_TYPE_SUCCESS';
export const FETCH_PAYMENT_TYPE_FAILURE = 'FETCH_PAYMENT_TYPE_FAILURE';

export const RESET_PAYMENT_TYPE = 'RESET_PAYMENT_TYPE';

// PAYMENT_METHOD

export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE'

export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAILURE = 'CREATE_PAYMENT_METHOD_FAILURE';

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';

export const FETCH_PAYMENT_METHOD_REQUEST = 'FETCH_PAYMENT_METHOD_REQUEST';
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS';
export const FETCH_PAYMENT_METHOD_FAILURE = 'FETCH_PAYMENT_METHOD_FAILURE';

export const RESET_PAYMENT_METHOD = 'RESET_PAYMENT_METHOD';

// UNIT

export const FETCH_UNITS_REQUEST = 'FETCH_UNITS_REQUEST';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE'

export const CREATE_UNIT_REQUEST = 'CREATE_UNIT_REQUEST';
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS';
export const CREATE_UNIT_FAILURE = 'CREATE_UNIT_FAILURE';

export const UPDATE_UNIT_REQUEST = 'UPDATE_UNIT_REQUEST';
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS';
export const UPDATE_UNIT_FAILURE = 'UPDATE_UNIT_FAILURE';

export const FETCH_UNIT_REQUEST = 'FETCH_UNIT_REQUEST';
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS';
export const FETCH_UNIT_FAILURE = 'FETCH_UNIT_FAILURE';

export const RESET_UNIT = 'RESET_UNIT';

// SALE_INVOICE_RANGE

export const FETCH_SALE_INVOICE_RANGES_REQUEST = 'FETCH_SALE_INVOICE_RANGES_REQUEST'
export const FETCH_SALE_INVOICE_RANGES_SUCCESS = 'FETCH_SALE_INVOICE_RANGES_SUCCESS'
export const FETCH_SALE_INVOICE_RANGES_FAILURE = 'FETCH_SALE_INVOICE_RANGES_FAILURE'

export const FETCH_SALE_INVOICE_RANGE_REQUEST = 'FETCH_SALE_INVOICE_RANGE_REQUEST'
export const FETCH_SALE_INVOICE_RANGE_SUCCESS = 'FETCH_SALE_INVOICE_RANGE_SUCCESS'
export const FETCH_SALE_INVOICE_RANGE_FAILURE = 'FETCH_SALE_INVOICE_RANGE_FAILURE'

export const CREATE_SALE_INVOICE_RANGE_REQUEST = 'CREATE_SALE_INVOICE_RANGE_REQUEST'
export const CREATE_SALE_INVOICE_RANGE_SUCCESS = 'CREATE_SALE_INVOICE_RANGE_SUCCESS'
export const CREATE_SALE_INVOICE_RANGE_FAILURE = 'CREATE_SALE_INVOICE_RANGE_FAILURE'

export const UPDATE_SALE_INVOICE_RANGE_REQUEST = 'UPDATE_SALE_INVOICE_RANGE_REQUEST'
export const UPDATE_SALE_INVOICE_RANGE_SUCCESS = 'UPDATE_SALE_INVOICE_RANGE_SUCCESS'
export const UPDATE_SALE_INVOICE_RANGE_FAILURE = 'UPDATE_SALE_INVOICE_RANGE_FAILURE'

export const RESET_SALE_INVOICE_RANGE = 'RESET_SALE_INVOICE_RANGE'

// SALE_INVOICE

export const FETCH_SALE_INVOICES_REQUEST = 'FETCH_SALE_INVOICES_REQUEST'
export const FETCH_SALE_INVOICES_SUCCESS = 'FETCH_SALE_INVOICES_SUCCESS'
export const FETCH_SALE_INVOICES_FAILURE = 'FETCH_SALE_INVOICES_FAILURE'

export const FETCH_SALE_INVOICE_REQUEST = 'FETCH_SALE_INVOICE_REQUEST'
export const FETCH_SALE_INVOICE_SUCCESS = 'FETCH_SALE_INVOICE_SUCCESS'
export const FETCH_SALE_INVOICE_FAILURE = 'FETCH_SALE_INVOICE_FAILURE'

export const CREATE_SALE_INVOICE_REQUEST = 'CREATE_SALE_INVOICE_REQUEST'
export const CREATE_SALE_INVOICE_SUCCESS = 'CREATE_SALE_INVOICE_SUCCESS'
export const CREATE_SALE_INVOICE_FAILURE = 'CREATE_SALE_INVOICE_FAILURE'

export const UPDATE_SALE_INVOICE_REQUEST = 'UPDATE_SALE_INVOICE_REQUEST'
export const UPDATE_SALE_INVOICE_SUCCESS = 'UPDATE_SALE_INVOICE_SUCCESS'
export const UPDATE_SALE_INVOICE_FAILURE = 'UPDATE_SALE_INVOICE_FAILURE'

export const RESET_SALE_INVOICE = 'RESET_SALE_INVOICE'

// BATCH

export const FETCH_BATCHES_REQUEST = 'FETCH_BATCHES_REQUEST'
export const FETCH_BATCHES_SUCCESS = 'FETCH_BATCHES_SUCCESS'
export const FETCH_BATCHES_FAILURE = 'FETCH_BATCHES_FAILURE'

export const FETCH_BATCH_REQUEST = 'FETCH_BATCH_REQUEST'
export const FETCH_BATCH_SUCCESS = 'FETCH_BATCH_SUCCESS'
export const FETCH_BATCH_FAILURE = 'FETCH_BATCH_FAILURE'

export const CREATE_BATCH_REQUEST = 'CREATE_BATCH_REQUEST'
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS'
export const CREATE_BATCH_FAILURE = 'CREATE_BATCH_FAILURE'

export const UPDATE_BATCH_REQUEST = 'UPDATE_BATCH_REQUEST'
export const UPDATE_BATCH_SUCCESS = 'UPDATE_BATCH_SUCCESS'
export const UPDATE_BATCH_FAILURE = 'UPDATE_BATCH_FAILURE'

export const RESET_BATCH = 'RESET_BATCH'

// STAGE

export const FETCH_STAGES_REQUEST = 'FETCH_STAGES_REQUEST'
export const FETCH_STAGES_SUCCESS = 'FETCH_STAGES_SUCCESS'
export const FETCH_STAGES_FAILURE = 'FETCH_STAGES_FAILURE'

export const FETCH_STAGE_REQUEST = 'FETCH_STAGE_REQUEST'
export const FETCH_STAGE_SUCCESS = 'FETCH_STAGE_SUCCESS'
export const FETCH_STAGE_FAILURE = 'FETCH_STAGE_FAILURE'

export const CREATE_STAGE_REQUEST = 'CREATE_STAGE_REQUEST'
export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS'
export const CREATE_STAGE_FAILURE = 'CREATE_STAGE_FAILURE'

export const UPDATE_STAGE_REQUEST = 'UPDATE_STAGE_REQUEST'
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS'
export const UPDATE_STAGE_FAILURE = 'UPDATE_STAGE_FAILURE'

export const RESET_STAGE = 'RESET_STAGE'

// WORK_ORDER

export const FETCH_WORK_ORDERS_REQUEST = 'FETCH_WORK_ORDERS_REQUEST'
export const FETCH_WORK_ORDERS_SUCCESS = 'FETCH_WORK_ORDERS_SUCCESS'
export const FETCH_WORK_ORDERS_FAILURE = 'FETCH_WORK_ORDERS_FAILURE'

export const FETCH_WORK_ORDER_REQUEST = 'FETCH_WORK_ORDER_REQUEST'
export const FETCH_WORK_ORDER_SUCCESS = 'FETCH_WORK_ORDER_SUCCESS'
export const FETCH_WORK_ORDER_FAILURE = 'FETCH_WORK_ORDER_FAILURE'

export const CREATE_WORK_ORDER_REQUEST = 'CREATE_WORK_ORDER_REQUEST'
export const CREATE_WORK_ORDER_SUCCESS = 'CREATE_WORK_ORDER_SUCCESS'
export const CREATE_WORK_ORDER_FAILURE = 'CREATE_WORK_ORDER_FAILURE'

export const UPDATE_WORK_ORDER_REQUEST = 'UPDATE_WORK_ORDER_REQUEST'
export const UPDATE_WORK_ORDER_SUCCESS = 'UPDATE_WORK_ORDER_SUCCESS'
export const UPDATE_WORK_ORDER_FAILURE = 'UPDATE_WORK_ORDER_FAILURE'

export const RESET_WORK_ORDER = 'RESET_WORK_ORDER'

// PURCHASE_INVOICE

export const FETCH_PURCHASE_INVOICES_REQUEST = 'FETCH_PURCHASE_INVOICES_REQUEST'
export const FETCH_PURCHASE_INVOICES_SUCCESS = 'FETCH_PURCHASE_INVOICES_SUCCESS'
export const FETCH_PURCHASE_INVOICES_FAILURE = 'FETCH_PURCHASE_INVOICES_FAILURE'

export const FETCH_PURCHASE_INVOICE_REQUEST = 'FETCH_PURCHASE_INVOICE_REQUEST'
export const FETCH_PURCHASE_INVOICE_SUCCESS = 'FETCH_PURCHASE_INVOICE_SUCCESS'
export const FETCH_PURCHASE_INVOICE_FAILURE = 'FETCH_PURCHASE_INVOICE_FAILURE'

export const CREATE_PURCHASE_INVOICE_REQUEST = 'CREATE_PURCHASE_INVOICE_REQUEST'
export const CREATE_PURCHASE_INVOICE_SUCCESS = 'CREATE_PURCHASE_INVOICE_SUCCESS'
export const CREATE_PURCHASE_INVOICE_FAILURE = 'CREATE_PURCHASE_INVOICE_FAILURE'

export const UPDATE_PURCHASE_INVOICE_REQUEST = 'UPDATE_PURCHASE_INVOICE_REQUEST'
export const UPDATE_PURCHASE_INVOICE_SUCCESS = 'UPDATE_PURCHASE_INVOICE_SUCCESS'
export const UPDATE_PURCHASE_INVOICE_FAILURE = 'UPDATE_PURCHASE_INVOICE_FAILURE'

export const RESET_PURCHASE_INVOICE = 'RESET_PURCHASE_INVOICE'

// PURCHASE_ORDER

export const FETCH_PURCHASE_ORDERS_REQUEST = 'FETCH_PURCHASE_ORDERS_REQUEST'
export const FETCH_PURCHASE_ORDERS_SUCCESS = 'FETCH_PURCHASE_ORDERS_SUCCESS'
export const FETCH_PURCHASE_ORDERS_FAILURE = 'FETCH_PURCHASE_ORDERS_FAILURE'

export const FETCH_PURCHASE_ORDER_REQUEST = 'FETCH_PURCHASE_ORDER_REQUEST'
export const FETCH_PURCHASE_ORDER_SUCCESS = 'FETCH_PURCHASE_ORDER_SUCCESS'
export const FETCH_PURCHASE_ORDER_FAILURE = 'FETCH_PURCHASE_ORDER_FAILURE'

export const CREATE_PURCHASE_ORDER_REQUEST = 'CREATE_PURCHASE_ORDER_REQUEST'
export const CREATE_PURCHASE_ORDER_SUCCESS = 'CREATE_PURCHASE_ORDER_SUCCESS'
export const CREATE_PURCHASE_ORDER_FAILURE = 'CREATE_PURCHASE_ORDER_FAILURE'

export const UPDATE_PURCHASE_ORDER_REQUEST = 'UPDATE_PURCHASE_ORDER_REQUEST'
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS'
export const UPDATE_PURCHASE_ORDER_FAILURE = 'UPDATE_PURCHASE_ORDER_FAILURE'

export const RESET_PURCHASE_ORDER = 'RESET_PURCHASE_ORDER'

// SUPPLIER

export const FETCH_SUPPLIERS_REQUEST = 'FETCH_SUPPLIERS_REQUEST'
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS'
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE'

export const FETCH_SUPPLIER_REQUEST = 'FETCH_SUPPLIER_REQUEST'
export const FETCH_SUPPLIER_SUCCESS = 'FETCH_SUPPLIER_SUCCESS'
export const FETCH_SUPPLIER_FAILURE = 'FETCH_SUPPLIER_FAILURE'

export const CREATE_SUPPLIER_REQUEST = 'CREATE_SUPPLIER_REQUEST'
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS'
export const CREATE_SUPPLIER_FAILURE = 'CREATE_SUPPLIER_FAILURE'

export const UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST'
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS'
export const UPDATE_SUPPLIER_FAILURE = 'UPDATE_SUPPLIER_FAILURE'

export const RESET_SUPPLIER = 'RESET_SUPPLIER'

// PARAMETER

export const FETCH_PARAMETERS_REQUEST = 'FETCH_PARAMETERS_REQUEST'
export const FETCH_PARAMETERS_SUCCESS = 'FETCH_PARAMETERS_SUCCESS'
export const FETCH_PARAMETERS_FAILURE = 'FETCH_PARAMETERS_FAILURE'

export const FETCH_PARAMETER_REQUEST = 'FETCH_PARAMETER_REQUEST'
export const FETCH_PARAMETER_SUCCESS = 'FETCH_PARAMETER_SUCCESS'
export const FETCH_PARAMETER_FAILURE = 'FETCH_PARAMETER_FAILURE'

export const CREATE_PARAMETER_REQUEST = 'CREATE_PARAMETER_REQUEST'
export const CREATE_PARAMETER_SUCCESS = 'CREATE_PARAMETER_SUCCESS'
export const CREATE_PARAMETER_FAILURE = 'CREATE_PARAMETER_FAILURE'

export const UPDATE_PARAMETER_REQUEST = 'UPDATE_PARAMETER_REQUEST'
export const UPDATE_PARAMETER_SUCCESS = 'UPDATE_PARAMETER_SUCCESS'
export const UPDATE_PARAMETER_FAILURE = 'UPDATE_PARAMETER_FAILURE'

export const RESET_PARAMETER = 'RESET_PARAMETER'

// ESTABLISHMENT

export const FETCH_ESTABLISHMENTS_REQUEST = 'FETCH_ESTABLISHMENTS_REQUEST'
export const FETCH_ESTABLISHMENTS_SUCCESS = 'FETCH_ESTABLISHMENTS_SUCCESS'
export const FETCH_ESTABLISHMENTS_FAILURE = 'FETCH_ESTABLISHMENTS_FAILURE'

export const FETCH_ESTABLISHMENT_REQUEST = 'FETCH_ESTABLISHMENT_REQUEST'
export const FETCH_ESTABLISHMENT_SUCCESS = 'FETCH_ESTABLISHMENT_SUCCESS'
export const FETCH_ESTABLISHMENT_FAILURE = 'FETCH_ESTABLISHMENT_FAILURE'

export const CREATE_ESTABLISHMENT_REQUEST = 'CREATE_ESTABLISHMENT_REQUEST'
export const CREATE_ESTABLISHMENT_SUCCESS = 'CREATE_ESTABLISHMENT_SUCCESS'
export const CREATE_ESTABLISHMENT_FAILURE = 'CREATE_ESTABLISHMENT_FAILURE'

export const UPDATE_ESTABLISHMENT_REQUEST = 'UPDATE_ESTABLISHMENT_REQUEST'
export const UPDATE_ESTABLISHMENT_SUCCESS = 'UPDATE_ESTABLISHMENT_SUCCESS'
export const UPDATE_ESTABLISHMENT_FAILURE = 'UPDATE_ESTABLISHMENT_FAILURE'

export const RESET_ESTABLISHMENT = 'RESET_ESTABLISHMENT'

// FISCAL_DOCUMENT

export const FETCH_FISCAL_DOCUMENTS_REQUEST = 'FETCH_FISCAL_DOCUMENTS_REQUEST'
export const FETCH_FISCAL_DOCUMENTS_SUCCESS = 'FETCH_FISCAL_DOCUMENTS_SUCCESS'
export const FETCH_FISCAL_DOCUMENTS_FAILURE = 'FETCH_FISCAL_DOCUMENTS_FAILURE'

export const FETCH_FISCAL_DOCUMENT_REQUEST = 'FETCH_FISCAL_DOCUMENT_REQUEST'
export const FETCH_FISCAL_DOCUMENT_SUCCESS = 'FETCH_FISCAL_DOCUMENT_SUCCESS'
export const FETCH_FISCAL_DOCUMENT_FAILURE = 'FETCH_FISCAL_DOCUMENT_FAILURE'

export const CREATE_FISCAL_DOCUMENT_REQUEST = 'CREATE_FISCAL_DOCUMENT_REQUEST'
export const CREATE_FISCAL_DOCUMENT_SUCCESS = 'CREATE_FISCAL_DOCUMENT_SUCCESS'
export const CREATE_FISCAL_DOCUMENT_FAILURE = 'CREATE_FISCAL_DOCUMENT_FAILURE'

export const UPDATE_FISCAL_DOCUMENT_REQUEST = 'UPDATE_FISCAL_DOCUMENT_REQUEST'
export const UPDATE_FISCAL_DOCUMENT_SUCCESS = 'UPDATE_FISCAL_DOCUMENT_SUCCESS'
export const UPDATE_FISCAL_DOCUMENT_FAILURE = 'UPDATE_FISCAL_DOCUMENT_FAILURE'

export const RESET_FISCAL_DOCUMENT = 'RESET_FISCAL_DOCUMENT'

// PRINT_AUTHORIZATION

export const FETCH_PRINT_AUTHORIZATIONS_REQUEST = 'FETCH_PRINT_AUTHORIZATIONS_REQUEST'
export const FETCH_PRINT_AUTHORIZATIONS_SUCCESS = 'FETCH_PRINT_AUTHORIZATIONS_SUCCESS'
export const FETCH_PRINT_AUTHORIZATIONS_FAILURE = 'FETCH_PRINT_AUTHORIZATIONS_FAILURE'

export const FETCH_PRINT_AUTHORIZATION_REQUEST = 'FETCH_PRINT_AUTHORIZATION_REQUEST'
export const FETCH_PRINT_AUTHORIZATION_SUCCESS = 'FETCH_PRINT_AUTHORIZATION_SUCCESS'
export const FETCH_PRINT_AUTHORIZATION_FAILURE = 'FETCH_PRINT_AUTHORIZATION_FAILURE'

export const CREATE_PRINT_AUTHORIZATION_REQUEST = 'CREATE_PRINT_AUTHORIZATION_REQUEST'
export const CREATE_PRINT_AUTHORIZATION_SUCCESS = 'CREATE_PRINT_AUTHORIZATION_SUCCESS'
export const CREATE_PRINT_AUTHORIZATION_FAILURE = 'CREATE_PRINT_AUTHORIZATION_FAILURE'

export const UPDATE_PRINT_AUTHORIZATION_REQUEST = 'UPDATE_PRINT_AUTHORIZATION_REQUEST'
export const UPDATE_PRINT_AUTHORIZATION_SUCCESS = 'UPDATE_PRINT_AUTHORIZATION_SUCCESS'
export const UPDATE_PRINT_AUTHORIZATION_FAILURE = 'UPDATE_PRINT_AUTHORIZATION_FAILURE'

export const RESET_PRINT_AUTHORIZATION = 'RESET_PRINT_AUTHORIZATION'

// SALE_POINT

export const FETCH_SALE_POINTS_REQUEST = 'FETCH_SALE_POINTS_REQUEST'
export const FETCH_SALE_POINTS_SUCCESS = 'FETCH_SALE_POINTS_SUCCESS'
export const FETCH_SALE_POINTS_FAILURE = 'FETCH_SALE_POINTS_FAILURE'

export const FETCH_SALE_POINT_REQUEST = 'FETCH_SALE_POINT_REQUEST'
export const FETCH_SALE_POINT_SUCCESS = 'FETCH_SALE_POINT_SUCCESS'
export const FETCH_SALE_POINT_FAILURE = 'FETCH_SALE_POINT_FAILURE'

export const CREATE_SALE_POINT_REQUEST = 'CREATE_SALE_POINT_REQUEST'
export const CREATE_SALE_POINT_SUCCESS = 'CREATE_SALE_POINT_SUCCESS'
export const CREATE_SALE_POINT_FAILURE = 'CREATE_SALE_POINT_FAILURE'

export const UPDATE_SALE_POINT_REQUEST = 'UPDATE_SALE_POINT_REQUEST'
export const UPDATE_SALE_POINT_SUCCESS = 'UPDATE_SALE_POINT_SUCCESS'
export const UPDATE_SALE_POINT_FAILURE = 'UPDATE_SALE_POINT_FAILURE'

export const RESET_SALE_POINT = 'RESET_SALE_POINT'

// REPORT

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST'
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS'
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE'
