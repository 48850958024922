// MODAL

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// Authentication

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// USER

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const RESET_USER = 'RESET_USER'

// CLIENT

export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const FETCH_CLIENT_REQUEST = 'FETCH_CLIENT_REQUEST'
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS'
export const FETCH_CLIENT_FAILURE = 'FETCH_CLIENT_FAILURE'

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE'

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE'

export const RESET_CLIENT = 'RESET_CLIENT'

// SALE_CONSIGNMENT

export const FETCH_SALE_CONSIGNMENTS_REQUEST = 'FETCH_SALE_CONSIGNMENTS_REQUEST'
export const FETCH_SALE_CONSIGNMENTS_SUCCESS = 'FETCH_SALE_CONSIGNMENTS_SUCCESS'
export const FETCH_SALE_CONSIGNMENTS_FAILURE = 'FETCH_SALE_CONSIGNMENTS_FAILURE'

export const FETCH_SALE_CONSIGNMENT_REQUEST = 'FETCH_SALE_CONSIGNMENT_REQUEST'
export const FETCH_SALE_CONSIGNMENT_SUCCESS = 'FETCH_SALE_CONSIGNMENT_SUCCESS'
export const FETCH_SALE_CONSIGNMENT_FAILURE = 'FETCH_SALE_CONSIGNMENT_FAILURE'

export const CREATE_SALE_CONSIGNMENT_REQUEST = 'CREATE_SALE_CONSIGNMENT_REQUEST'
export const CREATE_SALE_CONSIGNMENT_SUCCESS = 'CREATE_SALE_CONSIGNMENT_SUCCESS'
export const CREATE_SALE_CONSIGNMENT_FAILURE = 'CREATE_SALE_CONSIGNMENT_FAILURE'

export const UPDATE_SALE_CONSIGNMENT_REQUEST = 'UPDATE_SALE_CONSIGNMENT_REQUEST'
export const UPDATE_SALE_CONSIGNMENT_SUCCESS = 'UPDATE_SALE_CONSIGNMENT_SUCCESS'
export const UPDATE_SALE_CONSIGNMENT_FAILURE = 'UPDATE_SALE_CONSIGNMENT_FAILURE'

export const RESET_SALE_CONSIGNMENT = 'RESET_SALE_CONSIGNMENT'

// CHARACTERISTIC

export const FETCH_CHARACTERISTICS_REQUEST = 'FETCH_CHARACTERISTICS_REQUEST';
export const FETCH_CHARACTERISTICS_SUCCESS = 'FETCH_CHARACTERISTICS_SUCCESS';
export const FETCH_CHARACTERISTICS_FAILURE = 'FETCH_CHARACTERISTICS_FAILURE';

export const CREATE_CHARACTERISTIC_REQUEST = 'CREATE_CHARACTERISTIC_REQUEST';
export const CREATE_CHARACTERISTIC_SUCCESS = 'CREATE_CHARACTERISTIC_SUCCESS';
export const CREATE_CHARACTERISTIC_FAILURE = 'CREATE_CHARACTERISTIC_FAILURE';

export const UPDATE_CHARACTERISTIC_REQUEST = 'UPDATE_CHARACTERISTIC_REQUEST';
export const UPDATE_CHARACTERISTIC_SUCCESS = 'UPDATE_CHARACTERISTIC_SUCCESS';
export const UPDATE_CHARACTERISTIC_FAILURE = 'UPDATE_CHARACTERISTIC_FAILURE';

export const FETCH_CHARACTERISTIC_REQUEST = 'FETCH_CHARACTERISTIC_REQUEST';
export const FETCH_CHARACTERISTIC_SUCCESS = 'FETCH_CHARACTERISTIC_SUCCESS';
export const FETCH_CHARACTERISTIC_FAILURE = 'FETCH_CHARACTERISTIC_FAILURE';

export const RESET_CHARACTERISTIC = 'RESET_CHARACTERISTIC';

// PRICE_TYPE

export const FETCH_PRICE_TYPES_REQUEST = 'FETCH_PRICE_TYPES_REQUEST';
export const FETCH_PRICE_TYPES_SUCCESS = 'FETCH_PRICE_TYPES_SUCCESS';
export const FETCH_PRICE_TYPES_FAILURE = 'FETCH_PRICE_TYPES_FAILURE';

export const CREATE_PRICE_TYPE_REQUEST = 'CREATE_PRICE_TYPE_REQUEST';
export const CREATE_PRICE_TYPE_SUCCESS = 'CREATE_PRICE_TYPE_SUCCESS';
export const CREATE_PRICE_TYPE_FAILURE = 'CREATE_PRICE_TYPE_FAILURE';

export const UPDATE_PRICE_TYPE_REQUEST = 'UPDATE_PRICE_TYPE_REQUEST';
export const UPDATE_PRICE_TYPE_SUCCESS = 'UPDATE_PRICE_TYPE_SUCCESS';
export const UPDATE_PRICE_TYPE_FAILURE = 'UPDATE_PRICE_TYPE_FAILURE';

export const FETCH_PRICE_TYPE_REQUEST = 'FETCH_PRICE_TYPE_REQUEST';
export const FETCH_PRICE_TYPE_SUCCESS = 'FETCH_PRICE_TYPE_SUCCESS';
export const FETCH_PRICE_TYPE_FAILURE = 'FETCH_PRICE_TYPE_FAILURE';

export const RESET_PRICE_TYPE = 'RESET_PRICE_TYPE';

// PRODUCT_CATEGORY

export const FETCH_PRODUCT_CATEGORIES_REQUEST = 'FETCH_PRODUCT_CATEGORIES_REQUEST';
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_PRODUCT_CATEGORIES_FAILURE = 'FETCH_PRODUCT_CATEGORIES_FAILURE';

export const CREATE_PRODUCT_CATEGORY_REQUEST = 'CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE';

export const UPDATE_PRODUCT_CATEGORY_REQUEST = 'UPDATE_PRODUCT_CATEGORY_REQUEST';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS';
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'UPDATE_PRODUCT_CATEGORY_FAILURE';

export const FETCH_PRODUCT_CATEGORY_REQUEST = 'FETCH_PRODUCT_CATEGORY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_FAILURE = 'FETCH_PRODUCT_CATEGORY_FAILURE';

export const RESET_PRODUCT_CATEGORY = 'RESET_PRODUCT_CATEGORY';

// PRODUCT

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const RESET_PRODUCT = 'RESET_PRODUCT';

// SUBPRODUCT

export const FETCH_SUBPRODUCTS_REQUEST = 'FETCH_SUBPRODUCTS_REQUEST';
export const FETCH_SUBPRODUCTS_SUCCESS = 'FETCH_SUBPRODUCTS_SUCCESS';
export const FETCH_SUBPRODUCTS_FAILURE = 'FETCH_SUBPRODUCTS_FAILURE';

export const CREATE_SUBPRODUCT_REQUEST = 'CREATE_SUBPRODUCT_REQUEST';
export const CREATE_SUBPRODUCT_SUCCESS = 'CREATE_SUBPRODUCT_SUCCESS';
export const CREATE_SUBPRODUCT_FAILURE = 'CREATE_SUBPRODUCT_FAILURE';

export const UPDATE_SUBPRODUCT_REQUEST = 'UPDATE_SUBPRODUCT_REQUEST';
export const UPDATE_SUBPRODUCT_SUCCESS = 'UPDATE_SUBPRODUCT_SUCCESS';
export const UPDATE_SUBPRODUCT_FAILURE = 'UPDATE_SUBPRODUCT_FAILURE';

export const FETCH_SUBPRODUCT_REQUEST = 'FETCH_SUBPRODUCT_REQUEST';
export const FETCH_SUBPRODUCT_SUCCESS = 'FETCH_SUBPRODUCT_SUCCESS';
export const FETCH_SUBPRODUCT_FAILURE = 'FETCH_SUBPRODUCT_FAILURE';

export const RESET_SUBPRODUCT = 'RESET_SUBPRODUCT';

// WAREHOUSE_MOVEMENT

export const FETCH_WAREHOUSE_MOVEMENTS_REQUEST = 'FETCH_WAREHOUSE_MOVEMENTS_REQUEST';
export const FETCH_WAREHOUSE_MOVEMENTS_SUCCESS = 'FETCH_WAREHOUSE_MOVEMENTS_SUCCESS';
export const FETCH_WAREHOUSE_MOVEMENTS_FAILURE = 'FETCH_WAREHOUSE_MOVEMENTS_FAILURE';

export const CREATE_WAREHOUSE_MOVEMENT_REQUEST = 'CREATE_WAREHOUSE_MOVEMENT_REQUEST';
export const CREATE_WAREHOUSE_MOVEMENT_SUCCESS = 'CREATE_WAREHOUSE_MOVEMENT_SUCCESS';
export const CREATE_WAREHOUSE_MOVEMENT_FAILURE = 'CREATE_WAREHOUSE_MOVEMENT_FAILURE';

export const UPDATE_WAREHOUSE_MOVEMENT_REQUEST = 'UPDATE_WAREHOUSE_MOVEMENT_REQUEST';
export const UPDATE_WAREHOUSE_MOVEMENT_SUCCESS = 'UPDATE_WAREHOUSE_MOVEMENT_SUCCESS';
export const UPDATE_WAREHOUSE_MOVEMENT_FAILURE = 'UPDATE_WAREHOUSE_MOVEMENT_FAILURE';

export const FETCH_WAREHOUSE_MOVEMENT_REQUEST = 'FETCH_WAREHOUSE_MOVEMENT_REQUEST';
export const FETCH_WAREHOUSE_MOVEMENT_SUCCESS = 'FETCH_WAREHOUSE_MOVEMENT_SUCCESS';
export const FETCH_WAREHOUSE_MOVEMENT_FAILURE = 'FETCH_WAREHOUSE_MOVEMENT_FAILURE';

export const RESET_WAREHOUSE_MOVEMENT = 'RESET_WAREHOUSE_MOVEMENT';

// WAREHOUSE

export const FETCH_WAREHOUSES_REQUEST = 'FETCH_WAREHOUSES_REQUEST';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_FAILURE = 'FETCH_WAREHOUSES_FAILURE';

export const CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILURE = 'CREATE_WAREHOUSE_FAILURE';

export const UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILURE = 'UPDATE_WAREHOUSE_FAILURE';

export const FETCH_WAREHOUSE_REQUEST = 'FETCH_WAREHOUSE_REQUEST';
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS';
export const FETCH_WAREHOUSE_FAILURE = 'FETCH_WAREHOUSE_FAILURE';

export const RESET_WAREHOUSE = 'RESET_WAREHOUSE';

// DEPARTMENT

export const FETCH_DEPARTMENTS_REQUEST = 'FETCH_DEPARTMENTS_REQUEST';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE';

export const CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAILURE = 'CREATE_DEPARTMENT_FAILURE';

export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILURE = 'UPDATE_DEPARTMENT_FAILURE';

export const FETCH_DEPARTMENT_REQUEST = 'FETCH_DEPARTMENT_REQUEST';
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS';
export const FETCH_DEPARTMENT_FAILURE = 'FETCH_DEPARTMENT_FAILURE';

export const RESET_DEPARTMENT = 'RESET_DEPARTMENT';

// EMPLOYEE

export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';

export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const FETCH_EMPLOYEE_REQUEST = 'FETCH_EMPLOYEE_REQUEST';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';

export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';

// JOB_TITLE

export const FETCH_JOB_TITLES_REQUEST = 'FETCH_JOB_TITLES_REQUEST';
export const FETCH_JOB_TITLES_SUCCESS = 'FETCH_JOB_TITLES_SUCCESS';
export const FETCH_JOB_TITLES_FAILURE = 'FETCH_JOB_TITLES_FAILURE';

export const CREATE_JOB_TITLE_REQUEST = 'CREATE_JOB_TITLE_REQUEST';
export const CREATE_JOB_TITLE_SUCCESS = 'CREATE_JOB_TITLE_SUCCESS';
export const CREATE_JOB_TITLE_FAILURE = 'CREATE_JOB_TITLE_FAILURE';

export const UPDATE_JOB_TITLE_REQUEST = 'UPDATE_JOB_TITLE_REQUEST';
export const UPDATE_JOB_TITLE_SUCCESS = 'UPDATE_JOB_TITLE_SUCCESS';
export const UPDATE_JOB_TITLE_FAILURE = 'UPDATE_JOB_TITLE_FAILURE';

export const FETCH_JOB_TITLE_REQUEST = 'FETCH_JOB_TITLE_REQUEST';
export const FETCH_JOB_TITLE_SUCCESS = 'FETCH_JOB_TITLE_SUCCESS';
export const FETCH_JOB_TITLE_FAILURE = 'FETCH_JOB_TITLE_FAILURE';

export const RESET_JOB_TITLE = 'RESET_JOB_TITLE';

// PAYMENT_TYPE

export const FETCH_PAYMENT_TYPES_REQUEST = 'FETCH_PAYMENT_TYPES_REQUEST';
export const FETCH_PAYMENT_TYPES_SUCCESS = 'FETCH_PAYMENT_TYPES_SUCCESS';
export const FETCH_PAYMENT_TYPES_FAILURE = 'FETCH_PAYMENT_TYPES_FAILURE'

export const CREATE_PAYMENT_TYPE_REQUEST = 'CREATE_PAYMENT_TYPE_REQUEST';
export const CREATE_PAYMENT_TYPE_SUCCESS = 'CREATE_PAYMENT_TYPE_SUCCESS';
export const CREATE_PAYMENT_TYPE_FAILURE = 'CREATE_PAYMENT_TYPE_FAILURE';

export const UPDATE_PAYMENT_TYPE_REQUEST = 'UPDATE_PAYMENT_TYPE_REQUEST';
export const UPDATE_PAYMENT_TYPE_SUCCESS = 'UPDATE_PAYMENT_TYPE_SUCCESS';
export const UPDATE_PAYMENT_TYPE_FAILURE = 'UPDATE_PAYMENT_TYPE_FAILURE';

export const FETCH_PAYMENT_TYPE_REQUEST = 'FETCH_PAYMENT_TYPE_REQUEST';
export const FETCH_PAYMENT_TYPE_SUCCESS = 'FETCH_PAYMENT_TYPE_SUCCESS';
export const FETCH_PAYMENT_TYPE_FAILURE = 'FETCH_PAYMENT_TYPE_FAILURE';

export const RESET_PAYMENT_TYPE = 'RESET_PAYMENT_TYPE';

// SALE_INVOICE_RANGE

export const FETCH_SALE_INVOICE_RANGES_REQUEST = 'FETCH_SALE_INVOICE_RANGES_REQUEST'
export const FETCH_SALE_INVOICE_RANGES_SUCCESS = 'FETCH_SALE_INVOICE_RANGES_SUCCESS'
export const FETCH_SALE_INVOICE_RANGES_FAILURE = 'FETCH_SALE_INVOICE_RANGES_FAILURE'

export const FETCH_SALE_INVOICE_RANGE_REQUEST = 'FETCH_SALE_INVOICE_RANGE_REQUEST'
export const FETCH_SALE_INVOICE_RANGE_SUCCESS = 'FETCH_SALE_INVOICE_RANGE_SUCCESS'
export const FETCH_SALE_INVOICE_RANGE_FAILURE = 'FETCH_SALE_INVOICE_RANGE_FAILURE'

export const CREATE_SALE_INVOICE_RANGE_REQUEST = 'CREATE_SALE_INVOICE_RANGE_REQUEST'
export const CREATE_SALE_INVOICE_RANGE_SUCCESS = 'CREATE_SALE_INVOICE_RANGE_SUCCESS'
export const CREATE_SALE_INVOICE_RANGE_FAILURE = 'CREATE_SALE_INVOICE_RANGE_FAILURE'

export const UPDATE_SALE_INVOICE_RANGE_REQUEST = 'UPDATE_SALE_INVOICE_RANGE_REQUEST'
export const UPDATE_SALE_INVOICE_RANGE_SUCCESS = 'UPDATE_SALE_INVOICE_RANGE_SUCCESS'
export const UPDATE_SALE_INVOICE_RANGE_FAILURE = 'UPDATE_SALE_INVOICE_RANGE_FAILURE'

export const RESET_SALE_INVOICE_RANGE = 'RESET_SALE_INVOICE_RANGE'

// SALE_INVOICE

export const FETCH_SALE_INVOICES_REQUEST = 'FETCH_SALE_INVOICES_REQUEST'
export const FETCH_SALE_INVOICES_SUCCESS = 'FETCH_SALE_INVOICES_SUCCESS'
export const FETCH_SALE_INVOICES_FAILURE = 'FETCH_SALE_INVOICES_FAILURE'

export const FETCH_SALE_INVOICE_REQUEST = 'FETCH_SALE_INVOICE_REQUEST'
export const FETCH_SALE_INVOICE_SUCCESS = 'FETCH_SALE_INVOICE_SUCCESS'
export const FETCH_SALE_INVOICE_FAILURE = 'FETCH_SALE_INVOICE_FAILURE'

export const CREATE_SALE_INVOICE_REQUEST = 'CREATE_SALE_INVOICE_REQUEST'
export const CREATE_SALE_INVOICE_SUCCESS = 'CREATE_SALE_INVOICE_SUCCESS'
export const CREATE_SALE_INVOICE_FAILURE = 'CREATE_SALE_INVOICE_FAILURE'

export const UPDATE_SALE_INVOICE_REQUEST = 'UPDATE_SALE_INVOICE_REQUEST'
export const UPDATE_SALE_INVOICE_SUCCESS = 'UPDATE_SALE_INVOICE_SUCCESS'
export const UPDATE_SALE_INVOICE_FAILURE = 'UPDATE_SALE_INVOICE_FAILURE'

export const RESET_SALE_INVOICE = 'RESET_SALE_INVOICE'

// BATCH

export const FETCH_BATCHES_REQUEST = 'FETCH_BATCHES_REQUEST'
export const FETCH_BATCHES_SUCCESS = 'FETCH_BATCHES_SUCCESS'
export const FETCH_BATCHES_FAILURE = 'FETCH_BATCHES_FAILURE'

export const FETCH_BATCH_REQUEST = 'FETCH_BATCH_REQUEST'
export const FETCH_BATCH_SUCCESS = 'FETCH_BATCH_SUCCESS'
export const FETCH_BATCH_FAILURE = 'FETCH_BATCH_FAILURE'

export const CREATE_BATCH_REQUEST = 'CREATE_BATCH_REQUEST'
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS'
export const CREATE_BATCH_FAILURE = 'CREATE_BATCH_FAILURE'

export const UPDATE_BATCH_REQUEST = 'UPDATE_BATCH_REQUEST'
export const UPDATE_BATCH_SUCCESS = 'UPDATE_BATCH_SUCCESS'
export const UPDATE_BATCH_FAILURE = 'UPDATE_BATCH_FAILURE'

export const RESET_BATCH = 'RESET_BATCH'

// STAGE

export const FETCH_STAGES_REQUEST = 'FETCH_STAGES_REQUEST'
export const FETCH_STAGES_SUCCESS = 'FETCH_STAGES_SUCCESS'
export const FETCH_STAGES_FAILURE = 'FETCH_STAGES_FAILURE'

export const FETCH_STAGE_REQUEST = 'FETCH_STAGE_REQUEST'
export const FETCH_STAGE_SUCCESS = 'FETCH_STAGE_SUCCESS'
export const FETCH_STAGE_FAILURE = 'FETCH_STAGE_FAILURE'

export const CREATE_STAGE_REQUEST = 'CREATE_STAGE_REQUEST'
export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS'
export const CREATE_STAGE_FAILURE = 'CREATE_STAGE_FAILURE'

export const UPDATE_STAGE_REQUEST = 'UPDATE_STAGE_REQUEST'
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS'
export const UPDATE_STAGE_FAILURE = 'UPDATE_STAGE_FAILURE'

export const RESET_STAGE = 'RESET_STAGE'

// WORK_ORDER

export const FETCH_WORK_ORDERS_REQUEST = 'FETCH_WORK_ORDERS_REQUEST'
export const FETCH_WORK_ORDERS_SUCCESS = 'FETCH_WORK_ORDERS_SUCCESS'
export const FETCH_WORK_ORDERS_FAILURE = 'FETCH_WORK_ORDERS_FAILURE'

export const FETCH_WORK_ORDER_REQUEST = 'FETCH_WORK_ORDER_REQUEST'
export const FETCH_WORK_ORDER_SUCCESS = 'FETCH_WORK_ORDER_SUCCESS'
export const FETCH_WORK_ORDER_FAILURE = 'FETCH_WORK_ORDER_FAILURE'

export const CREATE_WORK_ORDER_REQUEST = 'CREATE_WORK_ORDER_REQUEST'
export const CREATE_WORK_ORDER_SUCCESS = 'CREATE_WORK_ORDER_SUCCESS'
export const CREATE_WORK_ORDER_FAILURE = 'CREATE_WORK_ORDER_FAILURE'

export const UPDATE_WORK_ORDER_REQUEST = 'UPDATE_WORK_ORDER_REQUEST'
export const UPDATE_WORK_ORDER_SUCCESS = 'UPDATE_WORK_ORDER_SUCCESS'
export const UPDATE_WORK_ORDER_FAILURE = 'UPDATE_WORK_ORDER_FAILURE'

export const RESET_WORK_ORDER = 'RESET_WORK_ORDER'

// PURCHASE_INVOICE

export const FETCH_PURCHASE_INVOICES_REQUEST = 'FETCH_PURCHASE_INVOICES_REQUEST'
export const FETCH_PURCHASE_INVOICES_SUCCESS = 'FETCH_PURCHASE_INVOICES_SUCCESS'
export const FETCH_PURCHASE_INVOICES_FAILURE = 'FETCH_PURCHASE_INVOICES_FAILURE'

export const FETCH_PURCHASE_INVOICE_REQUEST = 'FETCH_PURCHASE_INVOICE_REQUEST'
export const FETCH_PURCHASE_INVOICE_SUCCESS = 'FETCH_PURCHASE_INVOICE_SUCCESS'
export const FETCH_PURCHASE_INVOICE_FAILURE = 'FETCH_PURCHASE_INVOICE_FAILURE'

export const CREATE_PURCHASE_INVOICE_REQUEST = 'CREATE_PURCHASE_INVOICE_REQUEST'
export const CREATE_PURCHASE_INVOICE_SUCCESS = 'CREATE_PURCHASE_INVOICE_SUCCESS'
export const CREATE_PURCHASE_INVOICE_FAILURE = 'CREATE_PURCHASE_INVOICE_FAILURE'

export const UPDATE_PURCHASE_INVOICE_REQUEST = 'UPDATE_PURCHASE_INVOICE_REQUEST'
export const UPDATE_PURCHASE_INVOICE_SUCCESS = 'UPDATE_PURCHASE_INVOICE_SUCCESS'
export const UPDATE_PURCHASE_INVOICE_FAILURE = 'UPDATE_PURCHASE_INVOICE_FAILURE'

export const RESET_PURCHASE_INVOICE = 'RESET_PURCHASE_INVOICE'

// PURCHASE_ORDER

export const FETCH_PURCHASE_ORDERS_REQUEST = 'FETCH_PURCHASE_ORDERS_REQUEST'
export const FETCH_PURCHASE_ORDERS_SUCCESS = 'FETCH_PURCHASE_ORDERS_SUCCESS'
export const FETCH_PURCHASE_ORDERS_FAILURE = 'FETCH_PURCHASE_ORDERS_FAILURE'

export const FETCH_PURCHASE_ORDER_REQUEST = 'FETCH_PURCHASE_ORDER_REQUEST'
export const FETCH_PURCHASE_ORDER_SUCCESS = 'FETCH_PURCHASE_ORDER_SUCCESS'
export const FETCH_PURCHASE_ORDER_FAILURE = 'FETCH_PURCHASE_ORDER_FAILURE'

export const CREATE_PURCHASE_ORDER_REQUEST = 'CREATE_PURCHASE_ORDER_REQUEST'
export const CREATE_PURCHASE_ORDER_SUCCESS = 'CREATE_PURCHASE_ORDER_SUCCESS'
export const CREATE_PURCHASE_ORDER_FAILURE = 'CREATE_PURCHASE_ORDER_FAILURE'

export const UPDATE_PURCHASE_ORDER_REQUEST = 'UPDATE_PURCHASE_ORDER_REQUEST'
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS'
export const UPDATE_PURCHASE_ORDER_FAILURE = 'UPDATE_PURCHASE_ORDER_FAILURE'

export const RESET_PURCHASE_ORDER = 'RESET_PURCHASE_ORDER'

// SUPPLIER

export const FETCH_SUPPLIERS_REQUEST = 'FETCH_SUPPLIERS_REQUEST'
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS'
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE'

export const FETCH_SUPPLIER_REQUEST = 'FETCH_SUPPLIER_REQUEST'
export const FETCH_SUPPLIER_SUCCESS = 'FETCH_SUPPLIER_SUCCESS'
export const FETCH_SUPPLIER_FAILURE = 'FETCH_SUPPLIER_FAILURE'

export const CREATE_SUPPLIER_REQUEST = 'CREATE_SUPPLIER_REQUEST'
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS'
export const CREATE_SUPPLIER_FAILURE = 'CREATE_SUPPLIER_FAILURE'

export const UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST'
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS'
export const UPDATE_SUPPLIER_FAILURE = 'UPDATE_SUPPLIER_FAILURE'

export const RESET_SUPPLIER = 'RESET_SUPPLIER'