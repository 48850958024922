import * as ActionTypes from '../../../actions'
import paginate from '../../../reducers/paginate'
import { combineReducers } from 'redux'

export default combineReducers({
  departments: paginate({
    mapActionToKey: action => 'list',
    types: [
      ActionTypes.FETCH_DEPARTMENTS_REQUEST,
      ActionTypes.FETCH_DEPARTMENTS_SUCCESS,
      ActionTypes.FETCH_DEPARTMENTS_FAILURE
    ]
  }),
  department: paginate({
    mapActionToKey: action => 'department',
    types: [
      ActionTypes.FETCH_DEPARTMENT_REQUEST,
      ActionTypes.FETCH_DEPARTMENT_SUCCESS,
      ActionTypes.FETCH_DEPARTMENT_FAILURE
    ]
  })
})