import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';
import { Button, Dropdown, Grid, Header, Loader } from 'semantic-ui-react';
import moment from 'moment';
import DynamicImport, { Loading } from '../../../../routes/DynamicImport';

import { getOpenTeller } from '../../Tellers/selectors';
import { loadTellers } from '../../Tellers/actions';


const mapStateToProps = (state, ownProps) => {
  const {
    pagination: { tellers: { tellers: { list } } }
  } = state

  const tellersList = list || { ids: [] }

  return {
    teller: getOpenTeller(state),
    pagination: tellersList.pagination || {},
    isFetching: tellersList.isFetching || false
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadTellers: (params) => {
      dispatch(loadTellers(params))
    }
  }
}

class DashboardHeader extends Component {
  constructor(props) {
    super(props);

    this.exportFile = this.exportFile.bind(this);
  }

  componentDidMount() {
    const params = {
      q: {
        status: 'ABIERTA'
      },
      per_page: 1,
      page: 1
    }
    this.props.loadTellers(params)
  }

  exportFile(e) {
    e.preventDefault();

    const XLSX = (props) => (
      <DynamicImport load={() => import('xlsx')}>
        {(Component) => Component === null
          ? <Loading />
          : <Component {...props} />
        }
      </DynamicImport>
    )

    const saleInvoices = this.props.saleInvoices.map((saleInvoice) => (
      {
        "Número Factura": saleInvoice.invoice_number,
        "Fecha": moment(saleInvoice.sold_on).format('YYYY-MM-DD').toString(),
        "Cliente": saleInvoice.client.name,
        "Subtotal": saleInvoice.subtotal,
        "ISV": saleInvoice.tax,
        "Total": saleInvoice.total,
        "Tipo": saleInvoice.is_consignment ? "CONSIGNACION" : "FACTURA"
      }
    ))
    const ws = XLSX.utils.json_to_sheet(saleInvoices, {cellDates: true});
    ws['!cols'] = [
      { wch: 20 },
      { wch: 10 },
      { wch: 38 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 15 }
    ]

    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Ventas Reencauchadora Irama",
      Author: "Reencauchadora Irama"
    }
    const totalLines = saleInvoices.length || 0
    var range = {s:{c:0, r:0}, e:{c:6, r:totalLines}}

    for(var R = range.s.r; R <= range.e.r; ++R) {
      for(var C = range.s.c; C <= range.e.c; ++C) {
        var cell_address = {c:C, r:R};
        /* if an A1-style address is needed, encode the address */
        var cell = ws[XLSX.utils.encode_cell(cell_address)];

        if (R > 0) {
          switch (C) {
            case 0:
              cell.t = 's'
              continue
            case 1:
              cell.t = 'd'
              continue
            case 2:
              cell.t = 's'
              continue
            case 3:
              cell.t = 'n'
              cell.z = "#,##0.00"
              continue
            case 4:
              cell.t = 'n'
              cell.z = "#,##0.00"
              continue
            case 5:
              cell.t = 'n'
              cell.z = "#,##0.00"
              continue
            case 6:
              cell.t = 's'
              continue
            default:
              continue
          }
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, "Ventas");

    XLSX.writeFile(wb, "VENTAS.xlsx")
  }

  render() {
    const { teller } = this.props;

    return(
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as="h1">
              <Header.Content>
                Ventas <Loader size='small' inline active={this.props.isFetching} />
                <Header.Subheader>
                  {moment().format('LL').toString()}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign='right' >
            <Button content="Nuevo Pago" icon="plus" as={Link} to="/ventas/pagos/nuevo" />
            <Button content="Nueva Factura" icon="plus" as={Link} to="/ventas/facturas/nuevo" />
            {teller && teller._id ? (
              <Button content="Cerrar Caja" color="red" as={Link} to={`ventas/cajas/${teller && teller._id}`} />
            ) : (
              <Button content="Abrir Caja" color="green" as={Link} to={`ventas/cajas/nuevo`} />
            )}
            <Dropdown icon='ellipsis vertical' button className='icon' pointing={"top right"}>
              <Dropdown.Menu>
                <Dropdown.Item icon='file excel' text='Exportar a Excel' onClick={this.exportFile} />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(DashboardHeader));